import React, { useEffect, useState } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import { URL } from "../common/Route";
import DisclamerPdf from "../assets/doc/Disclaimer.pdf";
import {
  getTestApi,
  loginApi,
  newHomepageContent,
  userWalletLogin,
} from "../common/Api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ethers } from "ethers";

export default function LoginComponents() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [user, setUser] = useState([]);
  const [userType, setType] = useState("normaluser");
  const [show, setShow] = useState(false);
  const [check, setCheck] = useState(false);
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState(false);

  const handlelogin = async (em) => {
    setLoading(true);
    try {
      if (!em) {
        toast.error(`Please Enter Email`);
      } else {
        const { data } = await axios.post(`${URL}/user_login/`, {
          Email: em,
          User_type: userType,
          setType: "",
          device_unique_id: "",
        });
        if (data.status == "false") {
          localStorage.clear();
          toast(data.Msg);
        } else {
          localStorage.clear();
          toast(data.Msg);
          localStorage.setItem("user", JSON.stringify(data));
          localStorage.setItem("ph", 1);
          navigate("/otp/verify", {
            state: { isTfaEnable: false, login: true },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleEmaillogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });


  useEffect(() => {
    if (user.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          setEmail(res.data.email);
          setType("Email");
          setType("normaluser");
          handlelogin(res.data.email);
        })
        .catch((err) => console.log(err));
    }
  }, [user.access_token]);

  useEffect(() => {
    setShow(true);
    if (JSON.parse(localStorage.getItem("user"))) {
      localStorage.clear();
    }
  }, []);

  const handleClose = () => setShow(false);

  const handlecheck = (e) => {
    setDisable(!disable);
    setCheck(e.target.value);
  };

  const handleGoogleAuthetication = async (req, res) => {
    try {
      if (!email) {
        toast.error(`Please Enter Email`);
      } else {
        const { data } = await axios.post(`${URL}/user_login/`, {
          Email: email,
          User_type: userType,
          setType: "",
          device_unique_id: "",
          login_type: "google_authetication",
        });
        console.log(data);
        console.log(data.isTfaEnable);
        if (data.status == "false") {
          localStorage.clear();
          toast(data.Msg);
        } else {
          console.log(data.isTfaEnable);
          if (data.isTfaEnable == "enable") {
            localStorage.setItem("user", JSON.stringify(data));
            localStorage.setItem("ph", 1);
            navigate("/otp/verify", {
              state: { isTfaEnable: true, login: true },
            });
          } else {
            toast.error(`You nee to enable authetication first`);
          }
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleconnectwithwallet = async (req, res) => {
    setProcess(true);

    if (window.ethereum) {
      // Automatically detects if MetaMask or Trust Wallet is installed
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const userAddress = await signer.getAddress();

      const loginData = await userWalletLogin(userAddress);
      if (loginData.status == "false") {
        setProcess(false);
        return toast.error(loginData.Msg);
      }
      console.log( " the login data"+loginData)

      localStorage.setItem("user", JSON.stringify(loginData));
      localStorage.setItem("ph", 1);
      localStorage.setItem("otp", "true");
      localStorage.setItem("pin", "true");
      localStorage.setItem('hasRefreshed', "true")

      const info = await newHomepageContent(loginData.token, loginData.Version);
      const notificationMessage = formatNotificationMessage(
        info.notification_msg
      );

      localStorage.setItem("notificationMessage", notificationMessage);
      toast.success("Connecting to wallet successfully!");
      setProcess(false);
      //navigate("/user/dashboard");
      window.location.href = "/user/dashboard";

    } else {
      setProcess(false);
      console.log("MetaMask or Trust Wallet is not installed");
    }
  };

  const formatNotificationMessage = (message) => {
    return message
      .replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
      ) // Convert URLs to links
      .replace(/\r?\n/g, "<br />"); // Convert newlines to <br /> tags
  };

  return (
    <section className="singup_div bg-image">
      <div className="container item-center">
        <div className="otp_all_info">
          <div>
            <h2></h2>
          </div>
          <div className="varify_content">
            <div className="otp-title text-center">
              <h2 className="mb-0">Login To Your Account</h2>
            </div>
            <div className="social-container d-flex justify-content-center align-items-center gap-4 py-3 text-white pb-3">
              <Link
                onClick={() => handleEmaillogin()}
                style={{ color: "white" }}
              >
                <i className="fa-brands fa-google text-white border rounded-pill p-2">
                  {" "}
                </i>{" "}
                Login With Google{" "}
              </Link>
            </div>
            <div className="text-center pt-3">
              <p>Login In with your Account</p>
            </div>
            <ToastContainer />
            <div className="Pin_set_form">
              <div className="">
                <div className="d-flex flex-column gap-2">
                  <label >Email</label>
                  <input
                    type="email"
                    placeholder="Enter Your Email Id"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                  />
                </div>
                {loading == true ? (
                  <>
                    <div className="load" style={{ textAlign: "center" }}>
                      <div className="loader"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="submit_btn text-center pt-4">
                      <button
                        className="submit_button"
                        onClick={() => handlelogin(email)}
                      >
                        Login
                      </button>
                    </div>
                    <div className="submit_btn text-center pt-4">
                      <p>Or</p>
                      {/* <p style={{ textAlign: "center", marginTop: "30px", marginBottom: "0px" }}>OR</p> */}

                      {process ? (
                        <>
                          <p style={{ textAlign: "center" }}>Loading.....</p>
                        </>
                      ) : (
                        <>
                          <div className="text-center pt-4">
                            <button
                              className="google_athetication"
                              onClick={()=>handleconnectwithwallet()}
                            >
                              Connect With Wallet
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>  
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        backdrop={"static"}
        keyboard={false}
        show={show}
        onHide={handleClose}
      >
        <Modal.Body>
          <iframe
            src={DisclamerPdf}
            width="100%"
            height="400px"
          />
          <input
            type="checkbox"
            value={check}
            onChange={(e) => handlecheck(e)}
            className="text-white"
          />{" "}
          <span className="text-white">
            I hereby acknowledge and accept this Disclaimer.
          </span>
          <br />
          <Button
            variant="primary"
            onClick={handleClose}
            disabled={disable}
            className="mt-3"
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </section>
  );
}
