import React, { useEffect, useState } from "react";
import lodinggif from "../admin_assets/assets/gif/loadwalk.gif";
import fisrtimag from "../admin_assets/assets/img/illustrations/man.webp";
import secondimage from "../admin_assets/assets/img/illustrations/target.png";
import thirdimage from "../admin_assets/assets/img/illustrations/premimum.gif";


import { Link, useNavigate } from "react-router-dom";
import {
  // auto_update_missing_rewards,
  // autoRoiReward,
  // home_page_content,
  maximum_target,
  // JWperformanceBonus,
  // missing_reward_update_two,
  // MphealthReward,
  // mpPlanDetail,
  // process_rewards,
  // roll_process_rewards,
  // step_count_status_update,
  // update_plan_end_date,
  // user_details_two,
  // user_target_set,
  // mPlanAutoExpire,
} from "../common/Api";

import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import formatDate from "../common/DateFormatter";
import { color } from "framer-motion";

import { UseContext } from "../contextApi/DataContext";
// import { useNavigate } from "react-router-dom";

export default function DashboardBodyComponents() {
  const message = localStorage.getItem("notificationMessage");

  const [data, setData] = useState({});
  // const [token, setToken] = useState((JSON.parse(localStorage.getItem('user'))).token);   
  // const [info, setInfo] = useState({});
  const [PageDetail, setPageDetail] = useState("Home_page");
  const [cdate, setDate] = useState("");
  const [stepCount, setStepCount] = useState(0);
  
  // const [loading, setLoading] = useState(true);
  // const [cdate, setDate] = useState("");
  const [content, setContent] = useState("");
  const [max, setMax] = useState("");
  // const [convertedDate, setConvertedDate] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [airdrop, setAirdrop] = useState(false);
  const [Number, setNumber] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseAirdrop = () => setAirdrop(false);
  // const [mpdetail, setMpdetail] = useState({});

  // const get_home_page_detail = async () => {
  //   try {
  //     // setLoading(true)
  //     const info = await home_page_content();

  //     // Format the notification message
  //     const notificationMessage = formatNotificationMessage(info.notification_msg);
  //     setData({ ...info, notification_msg: notificationMessage });
  //     // setLoading(false);
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  const {loading,info,token ,handleTargetMaxCount,convertedDate,mpdetail,get_user_detail_two}=UseContext()

  // console.log(token)


  useEffect(() => {
    if (!user) {
      localStorage.clear();
      navigate("/login");
    }

    // Initial setup after the page is rendered
    setDate(new Date().toLocaleDateString());

    // get_user_detail_two();
  }, [user]); 
  // console.log(token)
  // console.log(info.target)

  // const get_plan_update_end_date = async () => {
  //   try {
  //     await update_plan_end_date(info.user_id);
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  // const get_roll_process_reward = async () => {
  //   try {
  //     await roll_process_rewards(info.user_id);
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };
  // const process_reward = async () => {
  //   try {
  //     await process_rewards(info.user_id);
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  // const mPlanExpire = async () => {
  //   try {
  //     await mPlanAutoExpire(info.user_id);
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  

  // const formatNotificationMessage = (message) => {
  //   return message
  //     .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>') // Convert URLs to links
  //     .replace(/\r?\n/g, '<br />'); // Convert newlines to <br /> tags
  // };


  // const get_user_detail_two = async () => {
  //   try {
  //     const data = await user_details_two(PageDetail);
  //     //console.log(data)
  //     setInfo(data);
  //     const date = data.plan_end_date;
  //     let dateMDY = `${date.getDate()}/${
  //       date.getMonth() + 1
  //     }/${date.getFullYear()}`;
  //     setConvertedDate(dateMDY);
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };




  // const Missing_reward_update = async () => {
  //   try {
  //     const date = new Date();
  //     const newdatec = formatDate(date);

  //     const data = await missing_reward_update_two(newdatec, info.Plan_Step);
  //     await auto_update_missing_rewards();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const mpPlan_detail = async () => {
  //   try {
  //     const data = await mpPlanDetail();
  //     //console.log(data)
  //     setMpdetail(data);
  //   } catch (error) {
  //     //console.log(data);
  //   }
  //   setLoading(false);
  // };

  // const jw_performance_bonus = async () => {
  //   try {
  //     await JWperformanceBonus();
  //   } catch (error) {
  //     toast.error(error);
  //   }
  //   // setLoading(false);
  // };

  // const mp_health_reward = async () => {
  //   try {
  //     const date = new Date();
  //     const newdatec = formatDate(date);

  //     const data = await MphealthReward(newdatec, info.Plan_Step);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   // setLoading(false);
  // };


  // useEffect(() => {
  //   if (!user) {
  //     localStorage.clear();
  //     navigate("/login");
  //   }

  //   // get_home_page_detail();
  //   get_user_detail_two();
  //   get_plan_update_end_date();
  //   get_roll_process_reward();
  //   process_reward();
  //   mp_health_reward();
  //   jw_performance_bonus();
  //   mpPlan_detail();
  //   autoRoiReward();
  //   if (info.Plan_Step) {
  //     Missing_reward_update();
  //   }

  //   let dt = new Date().toLocaleDateString();
  //   setDate(dt);
  // }, []);
  
  // useEffect(() => {
  //   if (!user) {
  //     localStorage.clear();
  //     navigate("/login");
  //   }

  //   // Initial setup after the page is rendered
  //   setDate(new Date().toLocaleDateString());

  //   get_user_detail_two();
  // }, [user]); // Ensure it re-runs when user changes

  // useEffect(() => {
  //   if (!info?.user_id) return; // Prevent unnecessary execution

  //   // Requesting animation frame to run functions after render
  //   requestAnimationFrame(() => {
  //     mpPlan_detail();
  //     get_plan_update_end_date();
  //     get_roll_process_reward();
  //     process_reward();
  //     mp_health_reward();
  //     jw_performance_bonus();
  //     autoRoiReward();
  //     mPlanExpire();

  //     if (info.Plan_Step) {
  //       Missing_reward_update();
  //     }
  //   });
  // }, [info?.user_id]);


  if (!user) {
    return navigate("/login");
  }

  const handleMaxTarget = async () => {
    try {
      const max = await maximum_target();
      setMax(max);
      setShow(true);
    } catch (error) {
      toast.error(error);
    }
  };

  // const handleTargetMaxCount = async () => {
  //   try {
  //     const target = document.querySelector("#target").value;
  //     const data = await user_target_set(target);
  //     toast.success(data.Msg);
  //     setShow(false);
  //     await get_user_detail_two();
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  // console.log( "the token "+ token)
  // if (loading) {
  //   return <Loader />
  // }



  return (
    <>
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
              <ToastContainer />
              <div className="col-lg-6 mb-4 main_bg d-none d-lg-block d-md-block h-100">
                <div
                  className="swiper-container swiper-container-horizontal swiper swiper-card-advance-bg"
                  id="swiper-with-pagination-cards"
                  style={{ borderRadius: "1.5rem" }}
                >
                  <div className="swiper-wrapper">
                    <div className="swiper-slide pb-5">
                      <div className="row">
                        <div className="col-lg-12 col-md-9 col-12 order-2 order-md-1">
                          <div className="row">
                            <div className="col-12">
                              <ul className="list-unstyled mb-0">
                                <li className="dashboard-title d-flex mb-2 align-items-center justify-content-between">
                                  <h5 className="text-white mb-0">
                                    We Can Now Convert Indoor Steps
                                  </h5>
                                  <p
                                    className="mb-0 fw-medium website-analytics-text-bg mb-0 text-white"
                                    role="button"
                                  >
                                    Activate Now
                                  </p>
                                  {token && <p>Step Count: {stepCount}</p>}
                                </li>
                                <li className="mt-4">
                                  <h6 className="text-white mb-1">
                                    WALK MORE AND EARN JW TOKENS
                                  </h6>
                                  <h5 className="text-white mb-1">Premium</h5>
                                  <p
                                    className="mb-0 pt-1"
                                    style={{
                                      whiteSpace: "wrap",
                                      fontSize: "14px",
                                      color: "#dadada !important",
                                      lineHeight: "1.4rem",
                                    }}
                                  >
                                    Welcome{" "}
                                    <span style={{ color: "#e1b969" }}>
                                      {user && user.email}
                                    </span>
                                    , You are in{" "}
                                    <span style={{ color: "#e1b969" }}>
                                      {info.plan_status_data}
                                    </span>{" "}
                                    plan you are eligible to earn
                                    {info.Plan_reward} USDT, up to a maximum of{" "}
                                    {info.Plan_Step} steps per day.
                                  </p>
                                </li>
                                <li className="mt-4">
                                  <h5 className="text-white mb-1">Monthly</h5>
                                  {mpdetail.plan && mpdetail.plan > 0 ? (
                                    <>
                                      <p
                                        className="mb-0 pt-1"
                                        style={{
                                          whiteSpace: "wrap",
                                          fontSize: "14px",
                                          color: "#dadada !important",
                                          lineHeight: "1.4rem",
                                        }}
                                      >
                                        Welcome{" "}
                                        <span style={{ color: "#e1b969" }}>
                                          {user && user.email}
                                        </span>
                                        , You are in{" "}
                                        <span style={{ color: "#e1b969" }}>
                                          {mpdetail.plan}
                                        </span>{" "}
                                        plan you are eligible to earn
                                        {mpdetail.Dailyreward} USDT, up to a
                                        maximum of {mpdetail.step} steps per
                                        day.
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p
                                        className="mb-0 pt-1"
                                        style={{
                                          whiteSpace: "wrap",
                                          fontSize: "14px",
                                          color: "#dadada !important",
                                          lineHeight: "1.4rem",
                                        }}
                                      >
                                        You have no monthly plan
                                      </p>
                                    </>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-pagination"></div>
                </div>
              </div>
              <div className="col-lg-6 main_bg d-block d-lg-none d-md-none">
                <div className="row">
                  <div className="col-lg-12 col-md-9 col-12 order-2 order-md-1">
                    <div className="row">
                      <div className="col-12">
                        <ul className="list-unstyled mb-0">
                          <li className="dashboard-title d-flex mb-2 align-items-center justify-content-between">
                            {token && <p>Step Count: {stepCount}</p>}
                          </li>
                          <li className="mt-4 text-center">
                            <h6 className="text-white mb-1">
                              WALK MORE AND EARN JW TOKENS
                            </h6>
                            <h5 className="text-white mb-1">Premium</h5>
                            <p
                              className="mb-3"
                              style={{
                                whiteSpace: "wrap",
                                fontSize: "14px",
                                color: "#dadada !important",
                                lineHeight: "1.4rem",
                              }}
                            >
                              Welcome{" "}
                              <span style={{ color: "#e1b969" }}>
                                {user && user.email}
                              </span>
                              , You are in{" "}
                              <span style={{ color: "#e1b969" }}>
                                {info.plan_status_data}
                              </span>{" "}
                              plan you are eligible to earn
                              {info.Plan_reward} USDT, up to a maximum of{" "}
                              {info.Plan_Step} steps per day.
                            </p>
                          </li>
                          <li className="mt-4">
                            <h6 className="text-white mb-1">Monthly</h6>
                            {mpdetail.plan && mpdetail.plan > 0 ? (
                              <>
                                <p
                                  className="mb-0 pt-1"
                                  style={{
                                    whiteSpace: "wrap",
                                    fontSize: "14px",
                                    color: "#dadada !important",
                                    lineHeight: "1.4rem",
                                  }}
                                >
                                  Welcome{" "}
                                  <span style={{ color: "#e1b969" }}>
                                    {user && user.email}
                                  </span>
                                  , You are in{" "}
                                  <span style={{ color: "#e1b969" }}>
                                    {mpdetail.plan}
                                  </span>{" "}
                                  plan you are eligible to earn
                                  {mpdetail.Dailyreward} USDT, up to a maximum
                                  of {mpdetail.step} steps per day.
                                </p>
                              </>
                            ) : (
                              <>
                                <p
                                  className="mb-0 pt-1"
                                  style={{
                                    whiteSpace: "wrap",
                                    fontSize: "14px",
                                    color: "#dadada !important",
                                    lineHeight: "1.4rem",
                                  }}
                                >
                                  You have no monthly plan
                                </p>
                              </>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
     
              {convertedDate != cdate && (
                <>
                  <div className="col-lg-3 col-sm-4 mb-4 rounded m d-flex align-items-center d-none d-lg-block d-md-block">
                    <div className="card m-0 p-0 h-100">
                      <div className="bg-successs d-flex justify-content-between align-items-center rounded p-4">
                        <div className="card_main_img card_main_imgDashBorad">
                          <img
                            src={fisrtimag}
                            alt=""
                            className="img-fluid "
                            style={{ width: "44%" }}
                          />
                        </div>
                        <div className="text-center">
                          <h5>{cdate}</h5>
                          <h6>Steps</h6>
                          <p className="lead">0</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-3 col-md-4 col-sm-4 mb-4 rounded d-flex align-items-center d-none d-lg-block d-md-block"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleMaxTarget()}
                  >
                    <div className="card m-0 p-0 h-100">
                      <div className="d-flex justify-content-between align-items-center p-4 mt-4">
                        <div className="col card_main_img">
                          <img
                            src={secondimage}
                            alt=""
                            className="img-fluid spx-1"
                          />
                        </div>
                        <div className="col text-center w-100">
                          <h5>Target Steps</h5>
                          <p className="lead">{info.target}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {convertedDate != cdate && (
                <>
                  <div className="col-6 mb-4 rounded m d-flex align-items-center d-block d-lg-none d-md-none">
                    <div className="stepcard1 card h-100 w-100 p-2">
                      <div className="d-flex justify-content-between align-items-center rounded">
                        <div className="card_main_img1">
                          <img src={fisrtimag} alt="" className="img-fluid" />
                        </div>
                        <div className="text-center card-title1 w-75">
                          <h5 className="mb-0">{cdate}</h5>
                          <h6 className="mb-0">Steps</h6>
                          <p className="lead mb-0">0</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-6 mb-4 rounded d-flex align-items-center d-block d-lg-none d-md-none"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleMaxTarget()}
                  >
                    <div
                      className="stepcard2 card h-100 w-100 p-2"
                      style={{ display: "grid", placeItems: "center" }}
                    >
                      <div className="d-flex justify-content-between align-items-center rounded">
                        <div className="card_main_img1">
                          <img src={secondimage} alt="" className="img-fluid" />
                        </div>
                        <div className="text-center card-title1 w-75">
                          <h5 className="mb-0">Target Steps</h5>
                          <p className="lead mb-0">{info.target}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="col-lg-12 mb-4 d-block d-lg-none d-md-none">
                <div className="card card-res daily-zoom px-4 py-3">
                  <p className="lh-lg daily-zoom-text">
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                  </p>
                </div>
              </div>

              <div className="col-lg-9 mb-4 h-100 overflow-hidden d-none d-lg-block d-md-block">
                <div className="card px-4 py-5 text-white">
                  <div dangerouslySetInnerHTML={{ __html: message }} />
                </div>
              </div>
              <div className="col-md-12 col-lg-3 mb-4 h-100">
                <div className="card px-4 mb-3 text-center d-lg-block d-md-block pb-1">
                  <span className="text-center">
                    <img
                      src={thirdimage}
                      alt=""
                      className="img-fluid gem_img"
                    />
                  </span>
                  <h4 className="text-center text-bold">Premium</h4>
                  <p className="text-center">Get More From your Steps</p>
                  <p className="text-center pb-2">
                    <Link to="/user/buy/plan" className="p-1">
                      View More
                    </Link>
                  </p>
                </div>

                <div className="card px-4 mb-2 text-center d-lg-block d-md-block pb-1">
                  <span className="text-center">
                    <img
                      src={thirdimage}
                      alt=""
                      className="img-fluid gem_img"
                    />
                  </span>
                  <h4 className="text-center text-bold">Monthly Plan</h4>
                  <p className="text-center">Get More From your Steps</p>
                  <p className="text-center pb-2">
                    <Link to="/user/buy-plan-new" className="p-1">
                      View More
                    </Link>
                  </p>
                </div>

                {/* <div className="card card-res bg-lin px-4 mb-2 pb-3  text-center d-block d-lg-none d-md-none">
                    <span className="text-center">
                      <img src={thirdimage} alt="" className="img-fluid gem_img" />
                    </span>
                    <h4 className="text-center text-bold text-white">Premium</h4>
                    <p className="text-center" style={{ color: "#d1c8aa" }}>Get More From your Steps</p>
                    <p className="text-center mt-2">
                      <a href="/user/buy/plan" style={{ background: "#fff", padding: "1rem 2rem", color: "#000" }} className='rounded-pill'>View More</a>
                    </p>
                  </div> */}
              </div>

              <div className="content-backdrop fade"></div>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Update Step Target</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="">
                  <select
                    className="form-control"
                    name="targetStep"
                    id="target"
                  >
                    {[...Array(50)].map((_, i) => (
                      <>
                        {info.target == (i + 1) * 1000 ? (
                          <option
                            key={i}
                            selected={true}
                            value={(i + 1) * 1000}
                          >
                            {(i + 1) * 1000}
                          </option>
                        ) : (
                          <option key={i} value={(i + 1) * 1000}>
                            {(i + 1) * 1000}
                          </option>
                        )}
                      </>
                    ))}
                  </select>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleTargetMaxCount}>
                  Update
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={airdrop} onHide={handleCloseAirdrop}>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="table table-responsive table-border">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td>Signup JW</td>
                        <td>{info?.Registration_JW}</td>
                      </tr>
                      <tr>
                        <td>Referal JW</td>
                        <td>{info?.Referal_JW}</td>
                      </tr>

                      <tr>
                        <td>Total JW</td>
                        <td>{info?.Referal_JW + info?.Registration_JW}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </>
      )}
    </>
  );
}
