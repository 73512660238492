import React, { useEffect, useState } from "react";
import { URL } from "../common/Route";
import walletnew from "../admin_assets/assets/img/illustrations/wallet_new.webp";
import {ConvertFormatDate} from "../common/DateFormatter";

export default function Wallet() {
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));
  const userBotDetail = JSON.parse(localStorage.getItem("userBotDetail"));
  const [activeTab, setActiveTab] = useState("trade_earning_summary");
  const [data, setData] = useState({
    trade_earning_summary: [],
    bot_referral_history: [],
    trade_referral_history: [],
  });

  const apiUrls = {
    trade_earning_summary: `${URL}/Trade_earning_summary/`,
    bot_referral_history: `${URL}/Bot_Referral_history/`,
    trade_referral_history: `${URL}/Trade_Referral_history/`,
  };

  useEffect(() => {
    fetchData(activeTab);
  }, [activeTab]);

  const fetchData = async (tabId) => {
    try {
      setIsLoading(true);
      const response = await fetch(apiUrls[tabId], {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Token: `${user.token}`,
        },
      });

      const result = await response.json();
      setData((prev) => ({ ...prev, [tabId]: result?.Data }));
      setIsLoading(false)
    } catch (error) {
      console.error(`Error fetching ${tabId}:`, error);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <div className="loading-spinner"></div>
      ) : (
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
           

            <div class="col-lg-6 mb-4">
              <div class="card px-4 pt-3 h-100">
                <div class=" d-flex flex-column gap-2">
                  <h4 class="text-center text-white">WALLET</h4>
                  <div class="d-flex justify-content-between align-items-center mb-3 pt-2 pt-lg-5 pt-md-5">
                    <div class="text-center mb-3">
                      <small class="para-color text-nowrap pb-2">
                        Daily Trade Profit
                      </small>
                      <br />
                      <p className="text-white">
                        {userBotDetail?.roibalance} USDT
                      </p>
                    </div>
                    <div class="text-center ">
                      <img
                        src={walletnew}
                        alt=""
                        class="img-fluid wallet_new_img"
                        style={{ maxWidth: "90px" }}
                      />
                    </div>
                    <div class="text-center">
                      <small class="para-color text-nowrap pb-2">
                        Trade Referral Income
                      </small>
                      <br />
                      <p className="text-white">
                        {userBotDetail?.tradereferalincome} USDT
                      </p>
                    </div>
                  </div>
                  <div>
                    <div class="text-center">
                      <small class="para-color text-nowrap pb-2">
                        Bot Referral Income
                      </small>
                      <br />
                      <p className="text-white">
                        {userBotDetail?.Boatreferalincome} USDT
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 mb-4">
              <div class="card px-4 pt-3 h-100">
                {/* Tabs Navigation */}
                <ul className="nav nav-tabs bg-transparent d-flex justify-content-center border-0 mb-3">
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        activeTab === "trade_earning_summary" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("trade_earning_summary")}
                    >
                      Daily Trade Profit
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        activeTab === "bot_referral_history" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("bot_referral_history")}
                    >
                      Bot Referrals
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        activeTab === "trade_referral_history" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("trade_referral_history")}
                    >
                      Trade Referrals
                    </button>
                  </li>
                </ul>

                {/* Tabs Content */}
                <div className="container">
                  <div className="tab-content bg-transparent">
                    {/* Trade Earning Summary */}
                    {activeTab === "trade_earning_summary" && (
                      <TabContent
                        data={data?.trade_earning_summary}
                        type="trade_earning_summary"
                        isLoading={isLoading}
                      />
                    )}

                    {/* Bot Referral History */}
                    {activeTab === "bot_referral_history" && (
                      <TabContent
                        data={data?.bot_referral_history}
                        type="bot_referral_history"
                        isLoading={isLoading}
                      />
                    )}

                    {/* Trade Referral History */}
                    {activeTab === "trade_referral_history" && (
                      <TabContent
                        data={data?.trade_referral_history}
                        type="trade_referral_history"
                        isLoading={isLoading}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}


const TabContent = ({ data = [], type, isLoading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const totalPages = Math.ceil(data.length / recordsPerPage);

  const paginatedData = data.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  const handlePrev = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNext = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan="2" className="text-center text-white">
            Loading...
          </td>
        </tr>
      );
    }

    if (!paginatedData || paginatedData.length === 0) {
      return (
        <tr>
          <td colSpan="2" className="text-center text-white">
            No Data Available
          </td>
        </tr>
      );
    }

    switch (type) {
      case "trade_earning_summary":
        return paginatedData.map((item, index) => (
          <tr key={index}>
            <td className="text-center">
              <small className="para-color text-nowrap">
                {ConvertFormatDate(item.created_on) || "N/A"}
              </small>
            </td>
            <td className="text-center">
              <p className="mb-0 fw-medium text-white">
                {item.Reward || "0.000000000"} USDT
              </p>
            </td>
          </tr>
        ));

      case "bot_referral_history":
      case "trade_referral_history":
        return paginatedData.map((item, index) => (
          <tr key={index}>
            <td>
              <h6 className="mb-0 text-white">{item.referral_id || "N/A"}</h6>
              <small className="para-color">{ConvertFormatDate(item.created_on) || "N/A"}</small>
            </td>
            <td>
              <p className="text-white pt-2">
                {item.reward || "0.000000000"} USDT
              </p>
            </td>
          </tr>
        ));

      default:
        return (
          <tr>
            <td colSpan="2" className="text-center text-white">
              No Data Available
            </td>
          </tr>
        );
    }
  };

  return (
    <div className="tab-pane fade show active">
      <div className="table-responsive">
        <table className="table table-borderless border-top">
          <tbody>{renderTableBody()}</tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {!isLoading && totalPages > 1 && (
        <div className="d-flex justify-content-center mt-3 ">
          <button
            className="btn btn-secondary me-2"
            onClick={handlePrev}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-white mx-2 mt-2">
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="btn btn-secondary ms-2"
            onClick={handleNext}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};
