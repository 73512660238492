import { useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useNavigate,
} from "react-router-dom";
import useAutoLogout from './useAutoLogout';
import HomePage from "./pages/HomePage";
import DashboardPage from "./pages/DashboardPage";
import SideBar from "./components/common/SideBar";
import Header from "./components/common/Header";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import OtpPage from "./pages/OtpPage";
import SetPinPage from "./pages/SetPinPage";
import RegisterPage from "./pages/RegisterPage";
import ReferalPage from "./pages/ReferalPage";
import WithdrawalPage from "./pages/WithdrawalPage";
import WalletPage from "./pages/WalletPage";
import BuyPlanBuy from "./pages/BuyPlanBuy";
import PremiumWallet from "./pages/PremiumWallet";
import InternalTransfer from "./pages/InternalTransfer";
import RollRewards from "./pages/RollRewards";
import Pharses from "./pages/Pharses";
import LoginPage from "./pages/LoginPage";
import ResetPin from "./pages/ResetPin";
import ComingSoonPage from "./pages/ComingSoonPage";
import ProfilePage from "./pages/ProfilePage";
import LoginHistory from "./pages/LoginHistory";
import SecurityPage from "./pages/SecurityPage";
import HelpCenter from "./components/HelpCenter";
import LeaderShipBonus from "./pages/LeaderShipBonus";
import Maintenance from "./pages/Maintenance";
import { URL } from "./common/Route";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import ReferalCodePage from "./pages/ReferalCodePage";
import WithdrawalHistoryPage from "./pages/WithdrawalHistoryPage";
import StakingWithdrawalHistory from "./pages/StakingWithdrawalHistory";
import InternalTransferHistoryPage from "./pages/InternalTransferHistoryPage";
import { get_profile } from "./common/Api";
import LeaderShipBonusWithdrawal from "./components/LeaderShipBonusWithdrawal";
import LeaderShipHistoryWithdrawal from "./components/LeaderShipHistoryWithdrawal";
import BalanceComponent from "./components/BalanceComponent";
import Trading from "./pages/Trading";
import Trade from "./pages/Trade";
import TradeHistory from "./pages/TradeHistory";
import BotHistory from "./pages/BotHistory";
import TradeWallet from "./pages/TradeWallet";
import Wallet from "./pages/Wallet";
import TradeWithdrawal from "./pages/TradeWithdrawal";
import StakingJW from "./components/Staking/StakingJW";
import NewStakeBuyHistory from "./components/Staking/NewStakeBuyHistory";
import StakeWithdrawal from "./components/Staking/StakeWithdrawal";
import ClaimReward from "./pages/ClaimReward";
import TradeHeader from "./components/common/TradeHeader";
import BuyPlanNew from "./components/BuyPlanNew";
import BuyPlanNewSummary from "./components/BuyPlanNewSummary";
import InternalTransferStaking from "./components/InternalTransferStaking";
import MonthlyWithdrawalPage from "./pages/MonthlyWithdrawalPage";
import FaqPage from "./pages/FaqPage";
import Staking from "./pages/Staking";
import SubscriptionPage from "./pages/SubscriptionPage";
import StakingPage from "./pages/StakingPage";
import Stakecedit from "./pages/Stakecedit";
import RechargeIssue from "./pages/RechargeIssue";
import SupportPage from "./pages/SupportPage";
import PriceConvertPage from "./pages/PriceConvertPage";

function App() {
  const [main, setMain] = useState("");
  const { isLoggedIn, timeRemaining } = useAutoLogout(10); // 30 minutes of inactivity timeout

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const maintainance = async () => {
    try {
      const response = await fetch(`${URL}/maintanance/`, {
        method: "GET",
      });

      const result = await response.json();
      if (result.Data && result.Data.length > 0) {
        //console.log(result.Data[0].site_maintenance_status);
        setMain(result.Data[0].site_maintenance_status);
      } else {
        console.error("No data found in the response");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    maintainance();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener when component mounts
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 768;

  const Layout = () => {
    const currentUrl = window.location.href;
    const user = JSON.parse(localStorage.getItem("user"));
    const otp = JSON.parse(localStorage.getItem("otp"));
    const pin = JSON.parse(localStorage.getItem("pin"));

    // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(isMobile ? false : true);
    
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
    
    const navigate = useNavigate();
    useEffect(() => {
      if (!user) {
        navigate("/");
      }
      if (!otp) {
        navigate("/otp/verify", { state: { login: true } });
      }
      if (!pin) {
        navigate("/set/pin", { state: { login: true } });
      }
      if (main === 1) {
        navigate("/maintenance");
      }
     
      const handleKeyDown = (event) => {
        if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
          event.preventDefault();
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [user, otp, pin, main, navigate]);

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     localStorage.removeItem('pin')
    //    navigate('/set/pin', { state: { login: true } });

    //   }, 30 * 60 * 1000); // Check every 30 minute

    //   return () => clearInterval(interval); // Clean up the interval on component unmount
    // }, []);

    return (
      <>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            {isSidebarOpen && (
              <SideBar
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
              />
            )}

            <div className="layout-page">
              {/* <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} /> */}
              {currentUrl.includes("trade") ? (
                <TradeHeader
                  isSidebarOpen={isSidebarOpen}
                  setIsSidebarOpen={setIsSidebarOpen}
                />
              ) : (
                <Header
                  isSidebarOpen={isSidebarOpen}
                  setIsSidebarOpen={setIsSidebarOpen}
                />
              )}
              <div className="content-wrapper">
                <Outlet />
              </div>
            </div>
          </div>

          <div className="layout-overlay layout-menu-toggle"></div>
          <div className="drag-target"></div>
        </div>
      </>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "user",
      element: <Layout />,
      children: [
        {
          path: "dashboard",
          element: <DashboardPage />,
        },
        {
          path: "referal",
          element: <ReferalPage />,
        },
        {
          path: "withdrawal",
          element: <WithdrawalPage />,
        },
       {
          path: "mp-withdrawal",
          element: <MonthlyWithdrawalPage />,
        },
        
        {
          path: "withdrawal/history",
          element: <WithdrawalHistoryPage />,
        },
        {
          path: "internaltransfer/history",
          element: <InternalTransferHistoryPage />,
        },
        {
          path: "wallet",
          element: <WalletPage />,
        },
        {
          path: "buy/plan",
          element: <BuyPlanBuy />,
        },
        {
          path: "buy-plan-new",
          element: <BuyPlanNew />,
        },
        {
          path: "buy-plan-details",
          element: <BuyPlanNewSummary />,
        },
        {
          path: "premium/wallet",
          element: <PremiumWallet />,
        },
        {
          path: "internal/transfer",
          element: <InternalTransfer />,
        },
        {
          path: "roll/on/rewards",
          element: <RollRewards />,
        },
        {
          path: "import/phrases",
          element: <Pharses />,
        },
        {
          path: "profile",
          element: <ProfilePage />,
        },
        {
          path: "leadship/bonus",
          element: <LeaderShipBonus />,
        },
        {
          path: "withdrawal-lb",
          element: <LeaderShipBonusWithdrawal />,
        },
        {
          path: "withdrawal-history-lb",
          element: <LeaderShipHistoryWithdrawal />,
        },

        {
          path: "balance",
          element: <BalanceComponent />,
        },

        // {
        //   path: 'stakewallet',
        //   element: <StakingPage />
        // },
        // {
        //   path: 'staking',
        //   element: <Staking />
        // },
        // {
        //   path: 'subscription',
        //   element: <SubscriptionPage />
        // },
        // {
        //   path: 'stakecredit',
        //   element: <Stakecedit />
        // },
        // {
        //   path: 'rechargeissue',
        //   element: <RechargeIssue />
        // },
        // {
        //   path: 'support',
        //   element: <SupportPage />
        // },
        // {
        //   path: 'priceconverter',
        //   element: <PriceConvertPage />
        // },
        {
          path: "loginhistory",
          element: <LoginHistory />,
        },
        {
          path: "security",
          element: <SecurityPage />,
        },
        // {
        //   path: 'faq',
        //   element: <FaqPage />
        // },

        {
          path: "trade/trading",
          element: <Trading />,
        },
        {
          path: "trade",
          element: <Trade />,
        },
        {
          path: "trade/trade-history",
          element: <TradeHistory />,
        },
        {
          path: "trade/bot-history",
          element: <BotHistory />,
        },
        {
          path: "trade/trade-wallet",
          element: <TradeWallet />,
        },
        {
          path: "trade/trade-withdrawal",
          element: <TradeWithdrawal />,
        },
        {
          path: "trade/bot-wallet",
          element: <Wallet />,
        },
        {
          path: "staking-jw",
          element: <StakingJW />,
        },
        {
          path: "stake/history",
          element: <StakingWithdrawalHistory />,
        },
        {
          path: "staking/histroy",
          element: <NewStakeBuyHistory />,
        },
        {
          path: "internal/transfer/stake",
          element: <InternalTransferStaking />,
        },

        {
          path: "staking/withdrawal",
          element: <StakeWithdrawal />,
        },
        {
          path: "trade/monthly-claim-reward",
          element: <ClaimReward />,
        },

        {
          path: "*",
          element: <ComingSoonPage />,
        },
      ],
    },

    {
      path: "/help/center",
      element: <HelpCenter />,
    },
    {
      path: "/Login",
      element: <LoginPage />,
    },
    {
      path: "/register",
      element: <RegisterPage />,
    },
    {
      path: "/referalcode/:ref",
      element: <ReferalCodePage />,
    },
    {
      path: "/otp/verify",
      element: <OtpPage />,
    },
    {
      path: "/set/pin",
      element: <SetPinPage />,
    },
    {
      path: "/reset/pin",
      element: <ResetPin />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/terms-and-conditions",
      element: <Terms />,
    },
    {
      path: "/account-delete",
      element: "Account Deleted Successfully!",
    },
    {
      path: "/maintenance",
      element: <Maintenance />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
