import React, { useEffect, useState } from "react";
import { Direct_referral_Tree, Direct_referral_list } from "../common/Api";
import DataTable from "react-data-table-component";
import { FaEye } from "react-icons/fa"; // Eye icon from react-icons
import { ConvertFormatDate } from "../common/DateFormatter";

export default function ReferalHistoryComponent({ childdata, setChildData }) {
  const [loading, setLoading] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null); // State to hold selected user's data

  // Define the columns, including the action button column
  const columns = [
    {
      name: "Name",
      selector: (row) => row.user_name,
      sortable: true,
    },
    {
      name: "Plan",
      selector: (row) => row.Plan || "Free",
      sortable: true,
    },
    {
      name: "MPlan",
      selector: (row) => row.MPlan || "Free",
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => ConvertFormatDate(row.Date),
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row) => ConvertFormatDate(row.Plan_end_date),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <button onClick={() => handleEyeClick(row)} className="btn btn-link">
          <FaEye />
        </button>
      ),
      allowOverflow: true,
      button: true,
    },
  ];

  // Handle the eye icon click, calling the API and updating the data
  const handleEyeClick = async (row) => {
    setLoading(true);
    try {
      const response = await Direct_referral_Tree(row.Email); // Call your API here, passing the relevant row if needed
      // Set selected user data to show it after navigating
      setSelectedUserData(row);
      setChildData(response);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = async () => {
    const response = await Direct_referral_list(); 
    setChildData(response);
    setSelectedUserData(null); // Reset user data when going back
  };

  if (selectedUserData) {
    // Display the selected user's direct referral tree or details
    return (
      <>
        <div>
          <button onClick={handleBackClick} className="btn btn-secondary mb-3">
            Back
          </button>
          {/* Render the selected user's direct referral tree or details here */}
          <div>
            <h3>Referral Tree for {selectedUserData.user_name}</h3>
            {/* You can render the details of the selectedUserData here */}
          </div>
        </div>

        <DataTable
          columns={columns}
          data={childdata}
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 50]} // Option to change rows per page
          highlightOnHover
          striped
          responsive
          progressPending={loading} // Show loading state while fetching data
        />
      </>
    );
  }

  return (
    <DataTable
      columns={columns}
      data={childdata}
      pagination
      paginationPerPage={10}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 50]} // Option to change rows per page
      highlightOnHover
      striped
      responsive
      progressPending={loading} // Show loading state while fetching data
    />
  );
}
