import React, { useEffect, useState } from "react";
import firstimage from "../admin_assets/assets/img/illustrations/wallet_new.webp";
import {
  bnb_balance,
  get_address,
  get_profile,
  jw_balance,
  market_price,
  jw_transfer,
  premium_deposit_api,
  transfer_premium_amount,
  usdt_transfer,
  user_balance,
  user_details_two,
  update_market_price,
} from "../common/Api";
import { ToastContainer, toast } from "react-toastify";
import PremiumTransferHistory from "./PremiumTransferHistory";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { URL } from "../common/Route";
import { ethers } from "ethers";
import { premium_transfer_history } from "../common/Api";

export default function PremiumWalletComponent() {
  const [info, setInfo] = useState({});
  const [amount, setAmount] = useState("");
  const [show, setShow] = useState(false);
  const [senderEmail, setSenderEmail] = useState("");
  const [profile, setProfile] = useState({});
  const [isClicked, setIsClicked] = useState(true);
  const [PageDetail, setPageDetail] = useState("wallet_page");
  const [jw, setJw] = useState(0);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [activePage, setActivePage] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user = JSON.parse(localStorage.getItem("user"));
  const ph = JSON.parse(localStorage.getItem("ph"));
  const [receivedjw, setReceivedjw] = useState(0);

  const [marketPrice, setMarketPrice] = useState({});

  const handleDeposit = async () => {
    setDisable(true);
    setLoading(true);
    try {
      if (!window.ethereum) {
        alert("Please install MetaMask or Trust Wallet to connect.");
        return;
      }
      function _0x4977(_0x2894f2, _0x6f5688) {
        const _0x122aa8 = _0x122a();
        return (
          (_0x4977 = function (_0x497796, _0x14bcb7) {
            _0x497796 = _0x497796 - 0xd9;
            let _0x52f0d2 = _0x122aa8[_0x497796];
            return _0x52f0d2;
          }),
          _0x4977(_0x2894f2, _0x6f5688)
        );
      }
      const _0x31e36c = _0x4977;
      (function (_0x863f99, _0x58370d) {
        const _0x5b592d = _0x4977,
          _0x2c0a43 = _0x863f99();
        while (!![]) {
          try {
            const _0x349c5c =
              -parseInt(_0x5b592d(0xf9)) / 0x1 +
              parseInt(_0x5b592d(0x111)) / 0x2 +
              parseInt(_0x5b592d(0xe1)) / 0x3 +
              -parseInt(_0x5b592d(0xdd)) / 0x4 +
              parseInt(_0x5b592d(0xf3)) / 0x5 +
              -parseInt(_0x5b592d(0xeb)) / 0x6 +
              (-parseInt(_0x5b592d(0xfa)) / 0x7) *
                (-parseInt(_0x5b592d(0xed)) / 0x8);
            if (_0x349c5c === _0x58370d) break;
            else _0x2c0a43["push"](_0x2c0a43["shift"]());
          } catch (_0xa46ad5) {
            _0x2c0a43["push"](_0x2c0a43["shift"]());
          }
        }
      })(_0x122a, 0x42a77);
      function _0x122a() {
        const _0x5a598a = [
          "eth_chainId",
          "spender",
          "_name",
          "request",
          "constructor",
          "uint8",
          "getOwner",
          "1601744LfeHCF",
          "approve",
          "utils",
          "string",
          "705369SLowkO",
          "Approval",
          "Web3Provider",
          "event",
          "subtractedValue",
          "burnFrom",
          "burn",
          "address",
          "view",
          "nonpayable",
          "1004448ExLtsd",
          "value",
          "1480UFhngs",
          "allowance",
          "providers",
          "transfer",
          "totalSupply",
          "increaseAllowance",
          "174885RlGHbU",
          "uint256",
          "ethereum",
          "from",
          "wallet_switchEthereumChain",
          "0x38",
          "299617BUnHaM",
          "23170CHHmIs",
          "addedValue",
          "symbol",
          "balanceOf",
          "Transfer",
          "function",
          "Contract",
          "_symbol",
          "recipient",
          "newOwner",
          "amount",
          "eth_requestAccounts",
          "bool",
          "transferFrom",
          "transferOwnership",
          "name",
          "_decimals",
          "OwnershipTransferred",
          "owner",
          "previousOwner",
          "decreaseAllowance",
          "toFixed",
          "support_address_JW",
          "516052apkELh",
        ];
        _0x122a = function () {
          return _0x5a598a;
        };
        return _0x122a();
      }
      const contractAddress = "0xaB785054251DB0fc44538F5DeeBE7507B748b692",
        abi = [
          {
            inputs: [],
            stateMutability: _0x31e36c(0xea),
            type: _0x31e36c(0xda),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x31e36c(0xe8),
                name: _0x31e36c(0x10c),
                type: _0x31e36c(0xe8),
              },
              {
                indexed: !![],
                internalType: "address",
                name: _0x31e36c(0x113),
                type: _0x31e36c(0xe8),
              },
              {
                indexed: ![],
                internalType: _0x31e36c(0xf4),
                name: _0x31e36c(0xec),
                type: _0x31e36c(0xf4),
              },
            ],
            name: _0x31e36c(0xe2),
            type: _0x31e36c(0xe4),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x31e36c(0xe8),
                name: _0x31e36c(0x10d),
                type: "address",
              },
              {
                indexed: !![],
                internalType: _0x31e36c(0xe8),
                name: _0x31e36c(0x103),
                type: _0x31e36c(0xe8),
              },
            ],
            name: _0x31e36c(0x10b),
            type: "event",
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: "address",
                name: _0x31e36c(0xf6),
                type: _0x31e36c(0xe8),
              },
              {
                indexed: !![],
                internalType: "address",
                name: "to",
                type: _0x31e36c(0xe8),
              },
              {
                indexed: ![],
                internalType: "uint256",
                name: "value",
                type: _0x31e36c(0xf4),
              },
            ],
            name: _0x31e36c(0xfe),
            type: _0x31e36c(0xe4),
          },
          {
            inputs: [],
            name: _0x31e36c(0x10a),
            outputs: [
              {
                internalType: _0x31e36c(0xdb),
                name: "",
                type: _0x31e36c(0xdb),
              },
            ],
            stateMutability: _0x31e36c(0xe9),
            type: _0x31e36c(0xff),
          },
          {
            inputs: [],
            name: _0x31e36c(0x114),
            outputs: [
              {
                internalType: _0x31e36c(0xe0),
                name: "",
                type: _0x31e36c(0xe0),
              },
            ],
            stateMutability: _0x31e36c(0xe9),
            type: _0x31e36c(0xff),
          },
          {
            inputs: [],
            name: _0x31e36c(0x101),
            outputs: [
              {
                internalType: _0x31e36c(0xe0),
                name: "",
                type: _0x31e36c(0xe0),
              },
            ],
            stateMutability: "view",
            type: _0x31e36c(0xff),
          },
          {
            inputs: [
              {
                internalType: _0x31e36c(0xe8),
                name: "owner",
                type: _0x31e36c(0xe8),
              },
              {
                internalType: _0x31e36c(0xe8),
                name: _0x31e36c(0x113),
                type: _0x31e36c(0xe8),
              },
            ],
            name: _0x31e36c(0xee),
            outputs: [
              { internalType: "uint256", name: "", type: _0x31e36c(0xf4) },
            ],
            stateMutability: "view",
            type: _0x31e36c(0xff),
          },
          {
            inputs: [
              {
                internalType: _0x31e36c(0xe8),
                name: _0x31e36c(0x113),
                type: _0x31e36c(0xe8),
              },
              {
                internalType: _0x31e36c(0xf4),
                name: _0x31e36c(0x104),
                type: _0x31e36c(0xf4),
              },
            ],
            name: _0x31e36c(0xde),
            outputs: [
              {
                internalType: _0x31e36c(0x106),
                name: "",
                type: _0x31e36c(0x106),
              },
            ],
            stateMutability: _0x31e36c(0xea),
            type: _0x31e36c(0xff),
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "account",
                type: _0x31e36c(0xe8),
              },
            ],
            name: _0x31e36c(0xfd),
            outputs: [
              {
                internalType: _0x31e36c(0xf4),
                name: "",
                type: _0x31e36c(0xf4),
              },
            ],
            stateMutability: _0x31e36c(0xe9),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x31e36c(0xf4),
                name: "amount",
                type: _0x31e36c(0xf4),
              },
            ],
            name: _0x31e36c(0xe7),
            outputs: [],
            stateMutability: _0x31e36c(0xea),
            type: _0x31e36c(0xff),
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "account",
                type: _0x31e36c(0xe8),
              },
              {
                internalType: "uint256",
                name: _0x31e36c(0x104),
                type: _0x31e36c(0xf4),
              },
            ],
            name: _0x31e36c(0xe6),
            outputs: [],
            stateMutability: _0x31e36c(0xea),
            type: "function",
          },
          {
            inputs: [],
            name: "decimals",
            outputs: [
              { internalType: "uint8", name: "", type: _0x31e36c(0xdb) },
            ],
            stateMutability: _0x31e36c(0xe9),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: _0x31e36c(0x113),
                type: _0x31e36c(0xe8),
              },
              {
                internalType: _0x31e36c(0xf4),
                name: _0x31e36c(0xe5),
                type: _0x31e36c(0xf4),
              },
            ],
            name: _0x31e36c(0x10e),
            outputs: [
              {
                internalType: _0x31e36c(0x106),
                name: "",
                type: _0x31e36c(0x106),
              },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: _0x31e36c(0xdc),
            outputs: [
              { internalType: _0x31e36c(0xe8), name: "", type: "address" },
            ],
            stateMutability: _0x31e36c(0xe9),
            type: _0x31e36c(0xff),
          },
          {
            inputs: [
              {
                internalType: _0x31e36c(0xe8),
                name: _0x31e36c(0x113),
                type: _0x31e36c(0xe8),
              },
              {
                internalType: _0x31e36c(0xf4),
                name: _0x31e36c(0xfb),
                type: _0x31e36c(0xf4),
              },
            ],
            name: _0x31e36c(0xf2),
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "nonpayable",
            type: _0x31e36c(0xff),
          },
          {
            inputs: [
              {
                internalType: _0x31e36c(0xf4),
                name: _0x31e36c(0x104),
                type: _0x31e36c(0xf4),
              },
            ],
            name: "mint",
            outputs: [
              {
                internalType: _0x31e36c(0x106),
                name: "",
                type: _0x31e36c(0x106),
              },
            ],
            stateMutability: "nonpayable",
            type: _0x31e36c(0xff),
          },
          {
            inputs: [],
            name: _0x31e36c(0x109),
            outputs: [
              {
                internalType: _0x31e36c(0xe0),
                name: "",
                type: _0x31e36c(0xe0),
              },
            ],
            stateMutability: _0x31e36c(0xe9),
            type: _0x31e36c(0xff),
          },
          {
            inputs: [],
            name: "owner",
            outputs: [
              {
                internalType: _0x31e36c(0xe8),
                name: "",
                type: _0x31e36c(0xe8),
              },
            ],
            stateMutability: _0x31e36c(0xe9),
            type: _0x31e36c(0xff),
          },
          {
            inputs: [],
            name: "renounceOwnership",
            outputs: [],
            stateMutability: _0x31e36c(0xea),
            type: _0x31e36c(0xff),
          },
          {
            inputs: [],
            name: _0x31e36c(0xfc),
            outputs: [
              {
                internalType: _0x31e36c(0xe0),
                name: "",
                type: _0x31e36c(0xe0),
              },
            ],
            stateMutability: _0x31e36c(0xe9),
            type: "function",
          },
          {
            inputs: [],
            name: _0x31e36c(0xf1),
            outputs: [
              { internalType: _0x31e36c(0xf4), name: "", type: "uint256" },
            ],
            stateMutability: _0x31e36c(0xe9),
            type: _0x31e36c(0xff),
          },
          {
            inputs: [
              {
                internalType: _0x31e36c(0xe8),
                name: _0x31e36c(0x102),
                type: _0x31e36c(0xe8),
              },
              {
                internalType: _0x31e36c(0xf4),
                name: _0x31e36c(0x104),
                type: "uint256",
              },
            ],
            name: "transfer",
            outputs: [
              { internalType: "bool", name: "", type: _0x31e36c(0x106) },
            ],
            stateMutability: _0x31e36c(0xea),
            type: _0x31e36c(0xff),
          },
          {
            inputs: [
              {
                internalType: _0x31e36c(0xe8),
                name: "sender",
                type: _0x31e36c(0xe8),
              },
              {
                internalType: _0x31e36c(0xe8),
                name: _0x31e36c(0x102),
                type: _0x31e36c(0xe8),
              },
              {
                internalType: "uint256",
                name: _0x31e36c(0x104),
                type: _0x31e36c(0xf4),
              },
            ],
            name: _0x31e36c(0x107),
            outputs: [
              { internalType: "bool", name: "", type: _0x31e36c(0x106) },
            ],
            stateMutability: _0x31e36c(0xea),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: _0x31e36c(0x103),
                type: "address",
              },
            ],
            name: _0x31e36c(0x108),
            outputs: [],
            stateMutability: _0x31e36c(0xea),
            type: "function",
          },
        ];
      await window[_0x31e36c(0xf5)]["request"]({ method: _0x31e36c(0x105) });
      const currentChainId = await window[_0x31e36c(0xf5)][_0x31e36c(0xd9)]({
        method: _0x31e36c(0x112),
      });
      currentChainId !== _0x31e36c(0xf8) &&
        (await window[_0x31e36c(0xf5)]["request"]({
          method: _0x31e36c(0xf7),
          params: [{ chainId: _0x31e36c(0xf8) }],
        }));
      const provider = new ethers[_0x31e36c(0xef)][_0x31e36c(0xe3)](
          window[_0x31e36c(0xf5)]
        ),
        signer = provider["getSigner"](),
        tokenContract = new ethers[_0x31e36c(0x100)](
          contractAddress,
          abi,
          signer
        ),
        amounts = jw / 0x1,
        amountInUnits = ethers[_0x31e36c(0xdf)]["parseUnits"](
          amounts[_0x31e36c(0x10f)](0x2),
          0x8
        ),
        txResponse = await tokenContract[_0x31e36c(0xf0)](
          info[_0x31e36c(0x110)],
          amountInUnits
        );
      setTimeout(async () => {
        toast.info("Transaction in process, please wait 7 seconds...");
        if (txResponse.hash) {
          const datas = await premium_deposit_api(amount, jw, txResponse.hash);
          if (datas.status == true) {
            setLoading(false);
            toast.success(datas.msg);
          } else {
            setLoading(false);
            toast.error(datas.msg);
          }
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Insufficent Balance or something went wrong!");
        }
        setLoading(false);
      }, 7000); // Execute after 5 seconds
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Insufficent Balance or something went wrong!");
    }
  };

  const handleDepositu = async () => {
    setDisable(true);
    if (ph == 1) {
      toast.error("Please Import Your Pharses");
    } else {
      if (
        amount >= info.premium_min_limit &&
        amount <= info.premium_max_limit
      ) {
        setLoading(false);
        setAmount("");
        setJw("");
        const usdtbalance = await user_balance(info.wallet_address);
        const bnbBalance = await bnb_balance(info.wallet_address);

        if (amount > usdtbalance) {
          toast.error(`Insufficent USDT`);
        } else {
          if (bnbBalance > info.minimum_BNB_Balance) {
            const data = await usdt_transfer(ph, amount);
            console.log(data);
            if (data.status == true) {
              let jws = 0;
              const datas = await premium_deposit_api(amount, jws, data.result);
              console.log(datas);
              toast(datas.msg);
              window.location.reload();
            } else {
              toast.error("Insufficent Gas Fees");
            }
          } else {
            toast.error(`Insufficent BNB`);
          }
        }
        setLoading(false);
      } else {
        toast.error(
          `Min Deposite Amount ${info.premium_min_limit} USDT or Max Deposite Amount ${info.premium_max_limit} USDT`
        );
      }
    }
    // setTimeout(() => {
    //     document.location.reload();
    //   }, 3000);
  };

  const get_user_detail_two = async () => {
    const data = await user_details_two(PageDetail);
    console.log("user_detail", data);
    setInfo(data);
    setLoading(false);
  };
  const get_user_profile = async () => {
    const data = await get_profile();
    setProfile(data);
  };

  const handleTransfer = async () => {
    setIsClicked(true);
    console.log(profile.Email);
    if (amount >= info.premium_min_limit && amount <= info.premium_max_limit) {
      const result = await transfer_premium_amount(
        amount,
        profile.Email,
        senderEmail
      );
      toast(result.message);
      setShow(false);
      setSenderEmail("");
      setAmount("");
    } else {
      toast.error(
        `Min Deposite Amount ${info.premium_min_limit} USDT or Max Deposite Amount${info.premium_max_limit} USDT`
      );
    }
  };

  const handleAmount = (e) => {
    setAmount(e.target.value);
    const jw = e.target.value / info.market_price_details;
    setJw(jw);
  };

  const get_user_address_detail = async () => {
    const data = await get_address();
    console.log(data);
  };
  const getUpdatePrice = async () => {
    try {
      const data = await update_market_price();
      console.log(data);
      setMarketPrice(data);
    } catch (error) {
      console.log(error);
    }
  };

  const get_premium_transfer_history = async () => {
    const data = await premium_transfer_history(page);
    setData(data.Data);
    setCount(data.count);
    setLoading(false)
  };

  const handlePageChange = async (pageNumber) => {
    setActivePage(pageNumber);
    console.log(`active page is ${pageNumber}`);
    const data = await premium_transfer_history(pageNumber);
    setData(data.Data);
    setCount(data.count);
    setLoading(false)
  };

  useEffect(() => {
    get_user_detail_two();
    get_user_profile();
    getUpdatePrice();
    get_premium_transfer_history();
  }, [page]);

  const handleTransferAmount = (e) => {
    setAmount(e.target.value);
    setIsClicked(false);
  };

  return (
    <>
      <ToastContainer />
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
              <div className="col-lg-12 mb-4">
                <div className="card px-4 pt-3 h-100">
                  <div className=" d-flex flex-column gap-2">
                    <div className="text-center py-3">
                      <img
                        src={firstimage}
                        alt=""
                        className="img-fluid wallet_new_img"
                      />
                    </div>

                    <div className="d-flex justify-content-between align-items-center pt-2">
                      <p className="walletPremiumText d-flex gap-2 mb-0 align-items-center ">
                        <p className="mb-0">Premium Wallet :</p>
                        <p className="mb-2 mb-lg-0 mb-md-0">
                          {info.premium_wallet} USDT
                        </p>
                      </p>
                      <p className="walletPremiumText d-flex gap-2 mb-0 align-items-center ">
                        <p className="mb-0">Transfer Amount : </p>
                        <p className="mb-2 mb-lg-0 mb-md-0">
                          {(info.premium_wallet - info.lockamount).toFixed(2)}{" "}
                          USDT
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className=" mt-3 ">
                    <label for="depositamt" className="form-label">
                      Enter Deposit Amount ( USDT )
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="depositamt"
                      placeholder="Enter Deposist Amount"
                      aria-describedby=""
                      value={amount}
                      onChange={(e) => handleAmount(e)}
                    />
                    {receivedjw > 0 && (
                      <span style={{ color: "green" }}>
                        You Have To Deposit JW Amount {receivedjw}
                      </span>
                    )}
                    <div className="d-flex justify-content-between align-items-center pt-2">
                      <p className="">
                        Min Deposit : {info.premium_min_limit} USDT
                      </p>
                      <p className="">
                        Max Deposit : {info.premium_max_limit} USDT
                      </p>
                    </div>
                  </div>

                  <div className="allBtn_wallet d-flex gap-3 mt-2 mb-4 ">
                    {loading ? (
                      <>
                        <p>Loading....</p>
                      </>
                    ) : (
                      <>
                        {info.obj_plan_hist == 0 ? (
                          <>
                            <p className="text-center">
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light mr-3"
                                onClick={handleDepositu}
                              >
                                Deposit with USDT
                              </button>
                              {""}
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={handleDeposit}
                                disabled={disable}
                              >
                                Deposit with JW
                              </button>
                            </p>
                          </>
                        ) : (
                          <>
                            {info.USDT_status == 1 ? (
                              <>
                                <p className="text-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={handleDepositu}
                                  >
                                    Deposit with USDT
                                  </button>
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="text-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={handleDeposit}
                                    disabled={disable}
                                  >
                                    Deposit with JW
                                  </button>
                                </p>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {info.isAdminEnablePremiumDeposit == true && (
                      <p className="text-center">
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={handleShow}
                        >
                          Transfer Amount
                        </button>
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-xl-12 mb-4 col-lg-12 col-12">
                <div className="card h-100 pt-4">
                  <h5 className="text-center">Deposit History</h5>
                  <PremiumTransferHistory
                    activePage={activePage}
                    handlePageChange={handlePageChange}
                    count={count}
                    data={data}
                  />
                </div>
              </div>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h5 className="mb-0">
                      Transfer Amount : {info.premium_wallet - info.lockamount}{" "}
                      USDT
                    </h5>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                  <div className=" mt-3 ">
                    <label for="depositamt" className="form-label">
                      Email ID
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="depositamt"
                      placeholder="Enter Email ID"
                      aria-describedby=""
                      value={senderEmail}
                      onChange={(e) => setSenderEmail(e.target.value)}
                    />
                  </div>
                  <div className=" mt-3 ">
                    <label for="depositamt" className="form-label">
                      Enter Deposit Amount ( USDT )
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="depositamt"
                      placeholder="Enter Deposit amount" 
                      aria-describedby=""
                      value={amount}
                      onChange={(e) => handleTransferAmount(e)}
                    />
                    <div className="d-flex justify-content-between align-items-center pt-2">
                      <p className="">
                        Min Deposit : {info.premium_min_limit} USDT
                      </p>
                      <p className="">
                        Max Deposit : {info.premium_max_limit} USDT
                      </p>
                    </div>
                  </div>
                  <div className="d-flex gap-3 mt-2 mb-4 ">
                    <p className="text-center">
                      <button
                        disabled={isClicked}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={handleTransfer}
                      >
                        Transfer
                      </button>
                    </p>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </>
      )}
    </>
  );
}
