import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";

export default function StakingDepositHistory({
  data,
  activePage,
  count,
  handlePageChange,
}) {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
  
    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  };
  return (
    <div className="table-responsive text-center">
      <table className="table table-borderless border-top text-center">
        <thead className="border-bottom text-center">
          <tr>
            <th>S.No</th>
            <th>Amount</th>
            <th>Hash</th>
            <th>Created On</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((e) => {
            return (
              <tr key={e.sno}>
                <td>{e.sno}</td>
                <td>{parseFloat(e.amount_usdt).toFixed(2)}</td>
               
                <td>
                  {e.Hash == "0x1468a5baaaca8d5e927ce129fd3c" ? (
                    <>
                      <p>Internal Transfer</p>
                    </>
                  ) : (
                    <>{e.Hash.slice(0, 10)}</>
                  )}
                </td>
                <td>{formatDate(e.created_on)}</td>
                <td>
                  <Link target="_blank" to={`https://bscscan.com/tx/${e.Hash}`}>
                    Go
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length > 10 && (
        <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={count}
          pageRangeDisplayed={count / 10}
          onChange={handlePageChange}
        />
      )}
    </div>
  );
}
