import React, { useEffect, useState } from "react";
import { referral_system } from "../common/Api";
import DataTable from "react-data-table-component";

export default function ReferalCommisionHistory() {
  const [data, setData] = useState([]);

  const get_user_referal_commison_history = async () => {
    const data = await referral_system();
    setData(data);
  };

  useEffect(() => {
    get_user_referal_commison_history();
  }, []);

  const columns = [
    {
      name: "Level 1",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "1st Percent",
      selector: (row) => `${row.commission_amount}%`,
      sortable: true,
    },
    {
      name: "2nd Percent",
      selector: (row) => `${row.second_commission_amount}%`,
      sortable: true,
    },
    {
      name: "Plan Required",
      selector: (row) => row.plan,
      sortable: true,
    },
  ];

  return (
    <DataTable
      className="table table-borderless border-top"
      columns={columns}
      data={data}
      pagination
      highlightOnHover
      striped
      responsive
    />
  );
}
