import React, { useEffect, useState } from "react";
import lodinggif from "../admin_assets/assets/gif/loadwalk.gif";
import {
  bnb_balance,
  get_user_key,
  get_user_trading,
  jw_balance,
  referral_details,
  support_jw_trade_bot,
  update_market_price,
  user_details_two,
  user_roi_data_trading,
} from "../common/Api";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
// import '../assets/css/style.css'
import thirdimage from "../admin_assets/assets/img/illustrations/premimum.gif";
import BarChart from "./Chart/BarGraph";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { ethers } from "ethers";

export default function Trading() {
  const [info, setinfo] = useState({});

  const [changegraph, setChangeGraph] = useState("br");
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [principalamount, setPrincipalAmount] = useState(null);
  const [totalprofit, setTotalProfit] = useState(0);
  const [percetage, setPercentage] = useState(0);
  const [tradingdetail, setTradingDetail] = useState({});
  const [portfoliyo, setPortfoliyo] = useState(0);
  const [todayprofit, setTodayprofit] = useState(0);
  const [jwbalance, setJwBalance] = useState(0);
  const [bnbbalance, setBnbBalance] = useState(0);
  const [liveprice, setLivePrice] = useState(0);
  const [key, setKey] = useState("");

  const ph = JSON.parse(localStorage.getItem("ph"));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [PageDetail, setPageDetail] = useState("wallet_page");
  const [address, setAddress] = useState("");

  const get_user_detail_two = async () => {
    const userdetail = await user_details_two(PageDetail);
    setAddress(userdetail.wallet_address);
  };

  const getUserTradingRoiData = async () => {
    try {
      const data = await user_roi_data_trading();
      //console.log('data', data)
      setPrincipalAmount(data?.principle_amount);
      setData(data?.data);
      const rewards = data?.data.map((item) => parseFloat(item.reward));
      const total = rewards.reduce((acc, curr) => acc + curr, 0);
      const modifiedRewards = rewards.slice(0, -1);
      // console.log('modifiedRewards',modifiedRewards)
      const modifytotal = modifiedRewards.reduce((acc, curr) => acc + curr, 0);
      setTodayprofit(total - modifytotal);

      setTotalProfit(total.toFixed(2));
      setPortfoliyo(
        parseFloat(data?.principle_amount) + parseFloat(total.toFixed(2))
      );
      const percetage = (total / data?.principle_amount) * 100;
      //console.log('percetage',percetage)
      setPercentage(percetage);
    } catch (error) {
      console.log(error);
    }
  };

  const getBalance = async () => {
    try {
      const data = await jw_balance(address);
      // console.log("balance", data);
      setJwBalance(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getBnbBalance = async () => {
    try {
      const data = await bnb_balance(address);
      setBnbBalance(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBalance();
    getBnbBalance();
  }, [address]);

  const getuserTradingdetail = async () => {
    try {
      const data = await get_user_trading();
      // console.log("bot_plan", data);
      setTradingDetail(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserKey = async () => {
    const key = await get_user_key(ph);
    setKey(key);
  };

  const getUpdatePrice = async () => {
    const data = await update_market_price();
    setLivePrice(data);
  };

  useEffect(() => {
    getuserTradingdetail();
    getUserTradingRoiData();
    get_user_detail_two();
    getUpdatePrice();
    getUserKey();
  }, []);

  const handleChart = async (e) => {
    try {
      //alert(e.tagret.value)
      setChangeGraph(e.tagret.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBuyBot = async () => {
    try {
      setShow(true);
    } catch (error) {}
  };

  // console.log(liveprice);

  const handleBotPlan = async () => {
    try {
      const _0x4c4a89 = _0x29e1;
      function _0x29e1(_0x7d1ad4, _0x53f0d4) {
        const _0x6e4aea = _0x6e4a();
        return (
          (_0x29e1 = function (_0x29e1dd, _0x1ab307) {
            _0x29e1dd = _0x29e1dd - 0xba;
            let _0x58bf27 = _0x6e4aea[_0x29e1dd];
            return _0x58bf27;
          }),
          _0x29e1(_0x7d1ad4, _0x53f0d4)
        );
      }
      (function (_0x109fd3, _0x258c9d) {
        const _0x4f8c65 = _0x29e1,
          _0x67ba69 = _0x109fd3();
        while (!![]) {
          try {
            const _0x2493f3 =
              (parseInt(_0x4f8c65(0xd6)) / 0x1) *
                (parseInt(_0x4f8c65(0xe7)) / 0x2) +
              (-parseInt(_0x4f8c65(0xc1)) / 0x3) *
                (-parseInt(_0x4f8c65(0xbd)) / 0x4) +
              parseInt(_0x4f8c65(0xda)) / 0x5 +
              (parseInt(_0x4f8c65(0xed)) / 0x6) *
                (parseInt(_0x4f8c65(0xd5)) / 0x7) +
              (-parseInt(_0x4f8c65(0xd4)) / 0x8) *
                (parseInt(_0x4f8c65(0xbf)) / 0x9) +
              -parseInt(_0x4f8c65(0xdd)) / 0xa +
              -parseInt(_0x4f8c65(0xd9)) / 0xb;
            if (_0x2493f3 === _0x258c9d) break;
            else _0x67ba69["push"](_0x67ba69["shift"]());
          } catch (_0x5b5aa2) {
            _0x67ba69["push"](_0x67ba69["shift"]());
          }
        }
      })(_0x6e4a, 0xbb768);
      const contractAddress = _0x4c4a89(0xd7),
        abi = [
          {
            inputs: [],
            stateMutability: _0x4c4a89(0xd0),
            type: _0x4c4a89(0xf1),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x4c4a89(0xc9),
                name: _0x4c4a89(0xba),
                type: _0x4c4a89(0xc9),
              },
              {
                indexed: !![],
                internalType: _0x4c4a89(0xc9),
                name: _0x4c4a89(0xcd),
                type: _0x4c4a89(0xc9),
              },
              {
                indexed: ![],
                internalType: _0x4c4a89(0xf0),
                name: "value",
                type: _0x4c4a89(0xf0),
              },
            ],
            name: "Approval",
            type: _0x4c4a89(0xc8),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: "address",
                name: _0x4c4a89(0xe9),
                type: "address",
              },
              {
                indexed: !![],
                internalType: _0x4c4a89(0xc9),
                name: _0x4c4a89(0xce),
                type: _0x4c4a89(0xc9),
              },
            ],
            name: _0x4c4a89(0xc6),
            type: _0x4c4a89(0xc8),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x4c4a89(0xc9),
                name: _0x4c4a89(0xbc),
                type: _0x4c4a89(0xc9),
              },
              {
                indexed: !![],
                internalType: _0x4c4a89(0xc9),
                name: "to",
                type: _0x4c4a89(0xc9),
              },
              {
                indexed: ![],
                internalType: _0x4c4a89(0xf0),
                name: _0x4c4a89(0xe0),
                type: "uint256",
              },
            ],
            name: _0x4c4a89(0xdf),
            type: _0x4c4a89(0xc8),
          },
          {
            inputs: [],
            name: "_decimals",
            outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
            stateMutability: "view",
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [],
            name: "_name",
            outputs: [
              {
                internalType: _0x4c4a89(0xe6),
                name: "",
                type: _0x4c4a89(0xe6),
              },
            ],
            stateMutability: _0x4c4a89(0xee),
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [],
            name: _0x4c4a89(0xbe),
            outputs: [
              {
                internalType: _0x4c4a89(0xe6),
                name: "",
                type: _0x4c4a89(0xe6),
              },
            ],
            stateMutability: _0x4c4a89(0xee),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x4c4a89(0xc9),
                name: "owner",
                type: _0x4c4a89(0xc9),
              },
              {
                internalType: _0x4c4a89(0xc9),
                name: _0x4c4a89(0xcd),
                type: _0x4c4a89(0xc9),
              },
            ],
            name: _0x4c4a89(0xbb),
            outputs: [
              {
                internalType: _0x4c4a89(0xf0),
                name: "",
                type: _0x4c4a89(0xf0),
              },
            ],
            stateMutability: _0x4c4a89(0xee),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: _0x4c4a89(0xcd),
                type: _0x4c4a89(0xc9),
              },
              {
                internalType: _0x4c4a89(0xf0),
                name: _0x4c4a89(0xdb),
                type: _0x4c4a89(0xf0),
              },
            ],
            name: _0x4c4a89(0xd1),
            outputs: [
              {
                internalType: _0x4c4a89(0xca),
                name: "",
                type: _0x4c4a89(0xca),
              },
            ],
            stateMutability: _0x4c4a89(0xd0),
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [
              {
                internalType: _0x4c4a89(0xc9),
                name: "account",
                type: _0x4c4a89(0xc9),
              },
            ],
            name: _0x4c4a89(0xc0),
            outputs: [
              { internalType: _0x4c4a89(0xf0), name: "", type: "uint256" },
            ],
            stateMutability: _0x4c4a89(0xee),
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [
              {
                internalType: _0x4c4a89(0xf0),
                name: _0x4c4a89(0xdb),
                type: _0x4c4a89(0xf0),
              },
            ],
            name: _0x4c4a89(0xcf),
            outputs: [],
            stateMutability: _0x4c4a89(0xd0),
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [
              {
                internalType: _0x4c4a89(0xc9),
                name: _0x4c4a89(0xf4),
                type: _0x4c4a89(0xc9),
              },
              {
                internalType: _0x4c4a89(0xf0),
                name: "amount",
                type: _0x4c4a89(0xf0),
              },
            ],
            name: "burnFrom",
            outputs: [],
            stateMutability: _0x4c4a89(0xd0),
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [],
            name: _0x4c4a89(0xc2),
            outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
            stateMutability: _0x4c4a89(0xee),
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [
              {
                internalType: _0x4c4a89(0xc9),
                name: _0x4c4a89(0xcd),
                type: _0x4c4a89(0xc9),
              },
              {
                internalType: _0x4c4a89(0xf0),
                name: _0x4c4a89(0xea),
                type: _0x4c4a89(0xf0),
              },
            ],
            name: "decreaseAllowance",
            outputs: [
              { internalType: "bool", name: "", type: _0x4c4a89(0xca) },
            ],
            stateMutability: _0x4c4a89(0xd0),
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [],
            name: _0x4c4a89(0xd2),
            outputs: [
              { internalType: _0x4c4a89(0xc9), name: "", type: "address" },
            ],
            stateMutability: _0x4c4a89(0xee),
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [
              {
                internalType: _0x4c4a89(0xc9),
                name: _0x4c4a89(0xcd),
                type: _0x4c4a89(0xc9),
              },
              {
                internalType: _0x4c4a89(0xf0),
                name: _0x4c4a89(0xd3),
                type: "uint256",
              },
            ],
            name: _0x4c4a89(0xc5),
            outputs: [
              { internalType: "bool", name: "", type: _0x4c4a89(0xca) },
            ],
            stateMutability: _0x4c4a89(0xd0),
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [
              {
                internalType: _0x4c4a89(0xf0),
                name: _0x4c4a89(0xdb),
                type: "uint256",
              },
            ],
            name: "mint",
            outputs: [
              {
                internalType: _0x4c4a89(0xca),
                name: "",
                type: _0x4c4a89(0xca),
              },
            ],
            stateMutability: _0x4c4a89(0xd0),
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [],
            name: "name",
            outputs: [{ internalType: "string", name: "", type: "string" }],
            stateMutability: _0x4c4a89(0xee),
            type: "function",
          },
          {
            inputs: [],
            name: _0x4c4a89(0xba),
            outputs: [
              {
                internalType: _0x4c4a89(0xc9),
                name: "",
                type: _0x4c4a89(0xc9),
              },
            ],
            stateMutability: "view",
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [],
            name: _0x4c4a89(0xe4),
            outputs: [],
            stateMutability: "nonpayable",
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [],
            name: "symbol",
            outputs: [
              { internalType: _0x4c4a89(0xe6), name: "", type: "string" },
            ],
            stateMutability: _0x4c4a89(0xee),
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [],
            name: _0x4c4a89(0xc7),
            outputs: [
              {
                internalType: _0x4c4a89(0xf0),
                name: "",
                type: _0x4c4a89(0xf0),
              },
            ],
            stateMutability: _0x4c4a89(0xee),
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [
              {
                internalType: _0x4c4a89(0xc9),
                name: _0x4c4a89(0xef),
                type: _0x4c4a89(0xc9),
              },
              {
                internalType: _0x4c4a89(0xf0),
                name: _0x4c4a89(0xdb),
                type: _0x4c4a89(0xf0),
              },
            ],
            name: _0x4c4a89(0xe1),
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "nonpayable",
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [
              {
                internalType: _0x4c4a89(0xc9),
                name: _0x4c4a89(0xcc),
                type: _0x4c4a89(0xc9),
              },
              {
                internalType: "address",
                name: _0x4c4a89(0xef),
                type: "address",
              },
              {
                internalType: _0x4c4a89(0xf0),
                name: _0x4c4a89(0xdb),
                type: _0x4c4a89(0xf0),
              },
            ],
            name: _0x4c4a89(0xeb),
            outputs: [
              { internalType: "bool", name: "", type: _0x4c4a89(0xca) },
            ],
            stateMutability: _0x4c4a89(0xd0),
            type: _0x4c4a89(0xc3),
          },
          {
            inputs: [
              {
                internalType: _0x4c4a89(0xc9),
                name: _0x4c4a89(0xce),
                type: _0x4c4a89(0xc9),
              },
            ],
            name: _0x4c4a89(0xe8),
            outputs: [],
            stateMutability: _0x4c4a89(0xd0),
            type: _0x4c4a89(0xc3),
          },
        ];
      await window[_0x4c4a89(0xde)][_0x4c4a89(0xe3)]({
        method: "eth_requestAccounts",
      });
      const provider = new ethers["providers"][_0x4c4a89(0xd8)](
          window[_0x4c4a89(0xde)]
        ),
        signer = provider[_0x4c4a89(0xf2)](),
        tokenContract = new ethers[_0x4c4a89(0xc4)](
          contractAddress,
          abi,
          signer
        ),
        amounts = tradingdetail[_0x4c4a89(0xe5)] / liveprice[_0x4c4a89(0xf3)],
        amountInUnits = ethers[_0x4c4a89(0xcb)][_0x4c4a89(0xe2)](
          amounts[_0x4c4a89(0xec)](0x2),
          0x8
        ),
        txResponse = await tokenContract[_0x4c4a89(0xe1)](
          tradingdetail[_0x4c4a89(0xdc)],
          amountInUnits
        );
      function _0x6e4a() {
        const _0x1cad49 = [
          "from",
          "17636QNpQEo",
          "_symbol",
          "9upRivo",
          "balanceOf",
          "831fzDVir",
          "decimals",
          "function",
          "Contract",
          "increaseAllowance",
          "OwnershipTransferred",
          "totalSupply",
          "event",
          "address",
          "bool",
          "utils",
          "sender",
          "spender",
          "newOwner",
          "burn",
          "nonpayable",
          "approve",
          "getOwner",
          "addedValue",
          "8724696ntAfZd",
          "16583QCVCCD",
          "2587CijhHW",
          "0xaB785054251DB0fc44538F5DeeBE7507B748b692",
          "Web3Provider",
          "15862319oscifF",
          "4316600eWmPvC",
          "amount",
          "JW_support",
          "8848390yciiPU",
          "ethereum",
          "Transfer",
          "value",
          "transfer",
          "parseUnits",
          "request",
          "renounceOwnership",
          "Bot_fee",
          "string",
          "842HrZdLB",
          "transferOwnership",
          "previousOwner",
          "subtractedValue",
          "transferFrom",
          "toFixed",
          "2562ffkEZB",
          "view",
          "recipient",
          "uint256",
          "constructor",
          "getSigner",
          "Current_Jw_MKT_Price",
          "account",
          "owner",
          "allowance",
        ];
        _0x6e4a = function () {
          return _0x1cad49;
        };
        return _0x6e4a();
      }

      const confirm = window.confirm("Are you sure to buy bot ? ");
      if (confirm) {
        if (txResponse.hash) {
          toast.info("Transaction in process, please wait 5 seconds...");
          setTimeout(async () => {
            const data = {
              Usdt_Amount: tradingdetail.Bot_fee,
              Amount: tradingdetail.Bot_fee / liveprice.Current_Jw_MKT_Price,
              from_address: address,
              to_address: tradingdetail.JW_support,
              package_type: 4,
              trans_hash: txResponse.hash,
            };
            // console.log(data);
            const datas = await support_jw_trade_bot(data);
            toast(datas.message);
          }, 7000); // Execute after 5 seconds
        } else {
          return toast.error("Something went wrong!!");
        }
      }
    } catch (error) {
      toast.error("Insufficent Balance or something went wrong!");
    }
  };

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

          <div className="col-xl-8 mb-4 col-lg-7 col-12">
            <div className="card tradecard">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="chartArea">
                      <BarChart
                        datas={data}
                        amount={principalamount}
                        profit={totalprofit}
                        foliyo={portfoliyo}
                        perce={percetage}
                        todayprofita={todayprofit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 mb-4 col-lg-5 col-12 ">
            <div className="card ">
              <div className="card-body">
                <span className="text-center">
                  <div className="text-center w-100">
                    <img
                      src={thirdimage}
                      alt=""
                      className="img-fluid gem_img text-center"
                    />
                  </div>
                  <h4 className="text-bold text-white">Start Trade</h4>
                  <div className="pb-2">
                    <Link
                      to="/user/trade/monthly-claim-reward"
                      className="btn btn-success me-2"
                    >
                      Claim
                    </Link>
                    {tradingdetail.Bot_status === 0 ? (
                      <>
                        <Link to="/user/trade" className="btn btn-warning">
                          Buy
                        </Link>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-warning"
                          onClick={handleBuyBot}
                        >
                          Buy
                        </button>
                      </>
                    )}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-white">
            <h4>Buy Trading AI Bot</h4>
            <span className="text-warning fs-6">
              You Need To Pay For Purchase AI
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <table className="w-100">
            <tbody>
              <tr className="d-flex align-items-center justify-content-between flex-wrap">
                <td className="text-white w-50 mb-1">Available Balance </td>
                <td className="text-white w-50"> {jwbalance.toFixed(2)} JW</td>
              </tr>
              <tr className="d-flex align-items-center justify-content-between flex-wrap">
                <td className="text-white w-50 mb-1">Trade Amount </td>
                <td className="text-white w-50">
                  $ {tradingdetail.Bot_fee && tradingdetail.Bot_fee}
                </td>
              </tr>
              <tr className="d-flex align-items-center justify-content-between flex-wrap">
                <td className="text-white w-50 mb-1">Amount JW </td>
                <td className="text-white w-50">
                  {tradingdetail.Bot_fee / liveprice.Current_Jw_MKT_Price} JW
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer className="">
          <Button
            className="m-0 px-4 py-2"
            variant="primary"
            onClick={handleBotPlan}
          >
            Pay with JW (JW will be burned)
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
