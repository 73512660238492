import React, { useEffect, useState } from "react";
import {
  bnb_balance,
  get_user_trading,
  get_withdraw_fees,
  resend_otp,
  shift_all_plan,
  stakeWithdrawal,
  update_market_price,
  update_plan_end_date,
  user_details_two,
  withdraw_request,
  updateBnbStatus,
  withdrawUSDT_request,
} from "../common/Api";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { ethers } from "ethers";

export default function TradeWithdrawal() {
  const ph = JSON.parse(localStorage.getItem("ph"));
  const navigation = useNavigate();

  const [email, setEmail] = useState(false);
  const [tfa, setTfa] = useState(false);
  const [info, setInfo] = useState({});
  const [plan, setPlan] = useState({});
  const [pageDetail, setPageDetail] = useState("Withdraw_page");
  const [userEmail, setUserEmail] = useState("");
  const [balance, setBalance] = useState(0);
  const [userReward, setUserRewards] = useState(0);
  const [rollonreward, setRollonreward] = useState(0);
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const [pin, setPin] = useState("");
  const [twoCode, setTwoFA] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [version, setVersion] = useState(0);
  const [fee, setFee] = useState(0);
  const [received, setReceived] = useState(0);
  const [receivedWallet, setReceivedWallet] = useState("");
  const [message, setmessage] = useState(null);
  const [directCount, setDirectCount] = useState(3);
  const [show, setShow] = useState(true);
  const [botdetail, setBotdetail] = useState({});
  const [marketPrice, setMarketPrice] = useState({});
  const [loading, setLoading] = useState(true);

  const getTradeBotDetail = async () => {
    try {
      const details = await get_user_trading();
      console.log("bot trade", details);
      setBotdetail(details);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleClose = () => setShow(false);
  const getUpdatePrice = async () => {
    try {
      const data = await update_market_price();
      console.log(" the market price"+data)
      console.log(data);
      setMarketPrice(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUpdatePrice();
    getTradeBotDetail();
  }, [ph]);

  const handleSendOtp = async () => {
    const result = await resend_otp();

    toast(result.Msg);
  };

  const handleAmount = (e) => {
    // console.log(e.target.value);
    setAmount(e.target.value);
    const wallet = document.querySelector("#selectwallet").value;
    // console.log("the selected wallet is"+  wallet)
    let value;
    if (wallet == 1) {
      value = (e.target.value * botdetail?.BotReffFee) / 100;
      console.log( " the value is"+ ( value))



      setReceived(
        (e.target.value - value) /
          Number(marketPrice?.Jw_MKT_Price_for_Withdraw)
        );
     
      // console.log("received"+ (e.target.value - value) /
      // Number(marketPrice?.Jw_MKT_Price_for_Withdraw))
      
      setReceivedWallet("JWC");

    } else if (wallet == 2) {
      value = (e.target.value * botdetail?.TradeReffFee) / 100;

      setReceived(e.target.value - value); 
      
      setReceivedWallet("USDT");
    } else {
      value = (e.target.value * botdetail?.TradeWithFee) / 100;
      setReceived(e.target.value - value);
      setReceivedWallet("USDT");
    }
  };

  const handleWalletChange = (e) => {
    setAmount(0);
    setReceived(0);
    setReceivedWallet("");
  };

  const handleWidthdrawal = async () => {
    try {
      if (amount == "") {
        return toast.error("Please enter the amount!");
      }
      if (botdetail?.WithdrawActive == false) {
        return toast.error(
          `You can withdraw your balance on ${botdetail?.Day} only`
        );
      }

      const wallet = document.querySelector("#selectwallet").value;
      if (wallet == 1) {
        if (amount >= Number(botdetail?.BotReffMinWithdraw)) {
          if (amount >= Number(botdetail?.Boatreferalincome)) {
            return toast.error(`Insufficent Balance in Bot Referal Income`);
          }
        } else {
          return toast.error(
            `The minimum withdrawal amount is ${botdetail?.BotReffMinWithdraw}.`
          );
        }
      } else if (wallet == 2) {
        if (amount >= Number(botdetail?.TradeReffMinWithdraw)) {
          if (amount >= Number(botdetail?.tradereferalincome)) {
            return toast.error(`Insufficent Balance in Trade Referal Income`);
          }
        } else {
          return toast.error(
            `The minimum withdrawal amount is ${botdetail?.TradeReffMinWithdraw}.`
          );
        }
      } else {
        if (amount >= Number(botdetail?.TradeMinWithdraw)) {
          if (amount >= Number(botdetail?.tradewithdrawblance)) {
            return toast.error(`Insufficent Balance in Trade wallet Income`);
          }
        } else {
          return toast.error(
            `The minimum withdrawal amount is ${botdetail?.TradeMinWithdraw}.`
          );
        }
      }

      if (emailOtp == "") {
        return toast.error(`Please Enter OTP`);
      }
      if (pin == "") {
        return toast.error(`Please Enter Pin`);
      }
      if (wallet != 1) {
        const bnbBalance = await bnb_balance(botdetail?.wallet_address);
        if (bnbBalance < 0.001) {
          return toast.error(`Insufficent BNB Balance`);
        }
      }

      const submitData = {
        user_withdraw_request: "0.0",
        premium_transfer_amt: "0.0",
        Address: botdetail.wallet_address,
        Amount: amount,
        Two_Fa: emailOtp,
        User_PK: ph,
        Wei_amount:
          botdetail?.PType == "INR"
            ? "123456789.0"
            : wallet == 1
            ? received 
            : 0,
        pin: pin,
        security_type: "EMAIL",
        wallet_type: wallet == 3 ? "5" : "4",
        stake_credit_converted: "0.0",
        price:
          wallet == 1
            ? amount - (amount * botdetail?.BotReffFee) / 100
            : wallet == 2
            ? amount - (amount * botdetail?.TradeReffFee) / 100
            : amount - (amount * botdetail?.TradeWithFee) / 100,
      };
      if (wallet == 1) {
        if (botdetail.BNBStatus == 1) {
          const result = await withdraw_request(submitData);
          toast(result.Msg);
          setInterval(() => {
            window.location.reload();
          }, 3000);

          return;
        }

        if (window.ethereum) {
          const initProvider = new ethers.providers.Web3Provider(
            window.ethereum
          );

          try {
            function _0x185f(_0x2e7d17, _0x11a92d) {
              const _0x34314a = _0x3431();
              return (
                (_0x185f = function (_0x185ff2, _0x1af513) {
                  _0x185ff2 = _0x185ff2 - 0x65;
                  let _0x5b34c8 = _0x34314a[_0x185ff2];
                  return _0x5b34c8;
                }),
                _0x185f(_0x2e7d17, _0x11a92d)
              );
            }
            function _0x3431() {
              const _0x113c48 = [
                "6141506GOQwyA",
                "0.00044",
                "179165olvtQq",
                "171FmmaDe",
                "ethereum",
                "parseEther",
                "eth_requestAccounts",
                "getSigner",
                "177HJiyhS",
                "66aMFSFb",
                "24047386ZuMxGZ",
                "296590uDKWnu",
                "22436nuKiZU",
                "parseUnits",
                "10868424RXrstM",
                "bnb_support",
                "sendTransaction",
                "1JmpKnL",
                "utils",
                "1287326OyYhpk",
              ];
              _0x3431 = function () {
                return _0x113c48;
              };
              return _0x3431();
            }
            const _0x5705ac = _0x185f;
            (function (_0x57e8f4, _0x4e825c) {
              const _0x45db2b = _0x185f,
                _0x4d3794 = _0x57e8f4();
              while (!![]) {
                try {
                  const _0x263f3a =
                    (-parseInt(_0x45db2b(0x70)) / 0x1) *
                      (parseInt(_0x45db2b(0x72)) / 0x2) +
                    (-parseInt(_0x45db2b(0x67)) / 0x3) *
                      (-parseInt(_0x45db2b(0x6b)) / 0x4) +
                    (-parseInt(_0x45db2b(0x75)) / 0x5) *
                      (-parseInt(_0x45db2b(0x68)) / 0x6) +
                    parseInt(_0x45db2b(0x73)) / 0x7 +
                    parseInt(_0x45db2b(0x6d)) / 0x8 +
                    (-parseInt(_0x45db2b(0x76)) / 0x9) *
                      (-parseInt(_0x45db2b(0x6a)) / 0xa) +
                    -parseInt(_0x45db2b(0x69)) / 0xb;
                  if (_0x263f3a === _0x4e825c) break;
                  else _0x4d3794["push"](_0x4d3794["shift"]());
                } catch (_0x2a15e9) {
                  _0x4d3794["push"](_0x4d3794["shift"]());
                }
              }
            })(_0x3431, 0xa99d1);
            const accounts = await window[_0x5705ac(0x77)]["request"]({
                method: _0x5705ac(0x65),
              }),
              signer = initProvider[_0x5705ac(0x66)](accounts[0x0]),
              transaction = {
                from: accounts[0],
                to: marketPrice[_0x5705ac(0x6e)],
                value: ethers[_0x5705ac(0x71)][_0x5705ac(0x78)](
                  _0x5705ac(0x74)
                ),
                gasLimit: ethers.utils.hexlify(100000),
                gasPrice: ethers[_0x5705ac(0x71)][_0x5705ac(0x6c)]("1", "gwei"),
              },
              tx = await signer[_0x5705ac(0x6f)](transaction);

            if (tx.hash) {
              await updateBnbStatus();
              const result = await withdraw_request(submitData);
              toast(result.Msg);
              setInterval(() => {
                window.location.reload();
              }, 5000);
            } else {
              toast.error("Please transfer BNB to the admin wallet first");
            }

            // Wait for the transaction to be mined (confirmed)
            await tx.wait();
          } catch (error) {
            if (error.code === 4001) {
              // User rejected the transaction
              //console.log('Transaction rejected by the user');
              toast.error("You rejected the transaction!");
            } else if (error.code === -32603) {
              // General errors like insufficient funds or network errors
              //console.error('Network error or insufficient funds:', error);
              toast.error(
                "A network error occurred or you have insufficient funds."
              );
            } else {
              // For any other errors
              // console.error('Transaction failed:', error);
              toast.error("Transaction failed. Please try again later.");
            }
          }
        } else {
          toast.error("MetaMask is not installed!");
        }

        //const data = await withdraw_request(submitData);
        // toast(data.Msg);

        // setInterval(() => {
        //   window.location.reload();
        // }, 2000);
      } else {
        if (wallet == 2 || wallet == 3) {
          if (botdetail.BNBStatus == 1) {
            const result = await withdrawUSDT_request(submitData);
            toast(result.Msg);
            setInterval(() => {
              window.location.reload();
            }, 3000);

            return;
          }

          if (window.ethereum) {
            const initProvider = new ethers.providers.Web3Provider(
              window.ethereum
            );

            try {
              function _0x185f(_0x2e7d17, _0x11a92d) {
                const _0x34314a = _0x3431();
                return (
                  (_0x185f = function (_0x185ff2, _0x1af513) {
                    _0x185ff2 = _0x185ff2 - 0x65;
                    let _0x5b34c8 = _0x34314a[_0x185ff2];
                    return _0x5b34c8;
                  }),
                  _0x185f(_0x2e7d17, _0x11a92d)
                );
              }
              function _0x3431() {
                const _0x113c48 = [
                  "6141506GOQwyA",
                  "0.00044",
                  "179165olvtQq",
                  "171FmmaDe",
                  "ethereum",
                  "parseEther",
                  "eth_requestAccounts",
                  "getSigner",
                  "177HJiyhS",
                  "66aMFSFb",
                  "24047386ZuMxGZ",
                  "296590uDKWnu",
                  "22436nuKiZU",
                  "parseUnits",
                  "10868424RXrstM",
                  "bnb_support",
                  "sendTransaction",
                  "1JmpKnL",
                  "utils",
                  "1287326OyYhpk",
                ];
                _0x3431 = function () {
                  return _0x113c48;
                };
                return _0x3431();
              }
              const _0x5705ac = _0x185f;
              (function (_0x57e8f4, _0x4e825c) {
                const _0x45db2b = _0x185f,
                  _0x4d3794 = _0x57e8f4();
                while (!![]) {
                  try {
                    const _0x263f3a =
                      (-parseInt(_0x45db2b(0x70)) / 0x1) *
                        (parseInt(_0x45db2b(0x72)) / 0x2) +
                      (-parseInt(_0x45db2b(0x67)) / 0x3) *
                        (-parseInt(_0x45db2b(0x6b)) / 0x4) +
                      (-parseInt(_0x45db2b(0x75)) / 0x5) *
                        (-parseInt(_0x45db2b(0x68)) / 0x6) +
                      parseInt(_0x45db2b(0x73)) / 0x7 +
                      parseInt(_0x45db2b(0x6d)) / 0x8 +
                      (-parseInt(_0x45db2b(0x76)) / 0x9) *
                        (-parseInt(_0x45db2b(0x6a)) / 0xa) +
                      -parseInt(_0x45db2b(0x69)) / 0xb;
                    if (_0x263f3a === _0x4e825c) break;
                    else _0x4d3794["push"](_0x4d3794["shift"]());
                  } catch (_0x2a15e9) {
                    _0x4d3794["push"](_0x4d3794["shift"]());
                  }
                }
              })(_0x3431, 0xa99d1);
              const accounts = await window[_0x5705ac(0x77)]["request"]({
                  method: _0x5705ac(0x65),
                }),
                signer = initProvider[_0x5705ac(0x66)](accounts[0x0]),
                transaction = {
                  to: marketPrice[_0x5705ac(0x6e)],
                  value: ethers[_0x5705ac(0x71)][_0x5705ac(0x78)](
                    _0x5705ac(0x74)
                  ),
                  gasLimit: 100000,
                  gasPrice: ethers[_0x5705ac(0x71)][_0x5705ac(0x6c)](
                    "1",
                    "gwei"
                  ),
                },
                tx = await signer[_0x5705ac(0x6f)](transaction);
              if (tx.hash) {
                await updateBnbStatus();
                const result = await withdrawUSDT_request(submitData);
                toast(result.Msg);
                setInterval(() => {
                  window.location.reload();
                }, 5000);
              } else {
                toast.error("Please transfer BNB to the admin wallet first");
              }

              // Wait for the transaction to be mined (confirmed)
              await tx.wait();
            } catch (error) {
              if (error.code === 4001) {
                // User rejected the transaction
                //console.log('Transaction rejected by the user');
                toast.error("You rejected the transaction!");
              } else if (error.code === -32603) {
                // General errors like insufficient funds or network errors
                //console.error('Network error or insufficient funds:', error);
                toast.error(
                  "A network error occurred or you have insufficient funds."
                );
              } else {
                // For any other errors
                // console.error('Transaction failed:', error);
                toast.error("Transaction failed. Please try again later.");
              }
            }
          } else {
            toast.error("MetaMask is not installed!");
          }
        }
        // const data = await withdrawUSDT_request(submitData);
        // toast(data.Msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAllmessage = (message) => {
    toast(`${message}`);
  };

  return (
    <>
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
          <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">
              <ToastContainer />
              <div class="col-lg-6 mb-4">
                <div class="card px-5 py-4 h-100">
                  <div class="content_internal_transfer">
                    <h5 class="text-center mb-0">TRADE WITHDRAWAL</h5>
                    <div class="d-flex justify-content-between align-items-center pt-4">
                      <div class="text-center">
                        <p class="mb-2">Bot Referal Income</p>
                        <h6>{botdetail?.Boatreferalincome || 0} USDT</h6>
                      </div>
                      <div class="text-center">
                        <p class="mb-2">Trade Referal Income</p>
                        <h6>{botdetail?.tradereferalincome || 0} USDT</h6>
                      </div>
                    </div>
                    <div class="text-center">
                      <p class="mb-2">Trade wallet</p>
                      <h6 class="mb-2">
                        {botdetail?.tradewithdrawblance} USDT
                      </h6>

                      <div class="d-flex justify-content-between align-items-center pt-4">
                        <div class="text-center">
                          <div class="day_count mb-2">
                            <span className="text-white">TWA </span>
                            <i
                              class="fa-solid fa-circle-exclamation text-white"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-custom-class="custom-tooltip"
                              data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)"
                              onClick={() =>
                                handleAllmessage(
                                  `Total amount available to withdrawal`
                                )
                              }
                            ></i>
                          </div>
                          <h6>{botdetail?.ActualTotalWithdrawAmount} USDT</h6>
                        </div>
                        <div class="text-center">
                          <div class="day_count mb-2">
                            <span className="text-white">BWA </span>
                            <i
                              class="fa-solid fa-circle-exclamation text-white"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-custom-class="custom-tooltip"
                              data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)"
                              onClick={() =>
                                handleAllmessage(
                                  `Balance amount available to withdrawal`
                                )
                              }
                            ></i>
                          </div>
                          <h6>
                            {botdetail?.TotalWithdrawAmount
                              ? Number(botdetail.TotalWithdrawAmount).toFixed(2)
                              : "0.00"}{" "}
                            USDT
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 mb-4">
                <div class="card h-100 px-4 pt-3">
                  <div class="row justify-content-between align-items-center">
                    <div>
                      <p class="text-end">
                        <Link to="/user/withdrawal/history">View History</Link>
                      </p>
                      <div class="mb-3">
                        <label for="selectpickerBasic" class="form-label">
                          Select Wallet
                        </label>
                        <div class="dropdown bootstrap-select w-100 dropup">
                          <div class="dropdown bootstrap-select w-100">
                            <select
                              id="selectwallet"
                              class="form-control selectpicker w-100"
                              data-style="btn-default"
                              tabindex="null"
                              onChange={(e) => handleWalletChange(e)}
                            >
                              <option value="">Select Wallet</option>
                              <option value="1">Bot Reward</option>
                              <option value="2">Trade Reward</option>
                              <option value="3">Trade Wallet</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="">
                        <label for="amount" class="form-label">
                          Amount(USDT)
                        </label>
                        <input
                          type="number"
                          class="form-control mb-2"
                          id="fiat_value"
                          placeholder="0.00"
                          aria-describedby=""
                          value={amount}
                          onChange={(e) => handleAmount(e)}
                        />
                        {received > 0 && (
                          <span style={{ color: "green" }}>
                            You Have To Recieved Amount {received}{" "}
                            {receivedWallet}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="content-backdrop fade"></div>
                </div>
              </div>
              <div class="col-12 mb-4">
                <div class="card h-100 px-4 pt-3">
                  <div class="row justify-content-between align-items-center">
                    <div>
                      <div class="col-md-12 my-3 px-0">
                        <label for="address" class="form-label">
                          Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="address"
                          placeholder=""
                          value={botdetail?.wallet_address}
                          onChange={(e) => setAddress(e.target.value)}
                          aria-describedby=""
                          readOnly={true}
                        />
                      </div>
                      <div class="col-md-12 my-3 px-0">
                        <label for="pin" class="form-label">
                          Enter Pin
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          id="address"
                          placeholder="Enter Your Pin"
                          value={pin}
                          onChange={(e) => setPin(e.target.value)}
                          aria-describedby=""
                          required={true}
                        />
                      </div>
                      <div class="row my-4">
                        <div class="col-12">
                          <div class="form-check custom-option custom-option-basic">
                            <label
                              class="form-check-label custom-option-content"
                              for="customRadioTemp2"
                            >
                              <input
                                name="customRadioTemp"
                                class="form-check-input"
                                type="radio"
                                value="email"
                                id="customRadioTemp2"
                                checked={true}
                              />
                              <span class="custom-option-header">
                                <span class="h6 mb-0">Email</span>
                              </span>
                            </label>
                          </div>
                        </div>

                        <div class="col-12 mt-3 position-relative">
                          <label for="2fa" class="form-label">
                            Email
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="email"
                            value={botdetail?.Email}
                            aria-describedby=""
                            onChange={(e) => setUserEmail(e.target.value)}
                            readOnly={true}
                          />
                          <button
                            className="sencOtpEmailBtn btn btn-primary waves-effect waves-light"
                            style={{ cursor: "pointer" }}
                            onClick={handleSendOtp}
                          >
                            Send OTP
                          </button>
                        </div>
                        <div class="col-12 mt-3 position-relative">
                          <label for="2fa" class="form-label">
                            Email OTP
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="emailOtp"
                            placeholder="OTP"
                            aria-describedby=""
                            value={emailOtp}
                            onChange={(e) => setEmailOtp(e.target.value)}
                          />
                        </div>
                      </div>

                      <button
                        type="button"
                        class="btn btn-primary waves-effect waves-light mb-4"
                        onClick={handleWidthdrawal}
                      >
                        Withdraw
                      </button>
                    </div>
                  </div>

                  <div class="mt-3">
                    <div class="content-backdrop fade"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="layout-overlay layout-menu-toggle"></div>

            <div class="drag-target"></div>
          </div>
        </>
      )}
    </>
  );
}
