import React, { useEffect, useState } from 'react'
import { Internal_Transfer_premium, stake_internal_transfer, stakeDetail, stakingHistory, stakingInternalTransfer, user_details_two } from '../common/Api'
import { ToastContainer, toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination";

export default function InternalTransferStaking() {

    const [amount, setAmount] = useState('')


    const [stakedetail, setStakeDetail] = useState({})
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [count, setCount] = useState(null)
    const [activePage, setActivePage] = useState(1);
      const [loading, setLoading] = useState(true);


    const get_premium_transfer_history = async () => {

        const pages = {
            pageno: page
        }
        const data = await stakingHistory(pages)
        console.log(data)
        setData(data.data)
        setCount(data.count)
        setLoading(false);
    }

    useEffect(() => {
        get_premium_transfer_history()
    }, [activePage])

    const handlePageChange = async (pageNumber) => {
        setActivePage(pageNumber)
        const pages = {
            pageno: pageNumber
        }
       // console.log(`active page is ${pageNumber}`);
        const data = await stakingHistory(pages)
        setData(data.data)
        setCount(data.count)
        setLoading(false);
    }





    const handleInternalTransfer = async (e) => {
        e.preventDefault()

        if (amount > 0) {
            const from = document.querySelector("#from_wallet").value
            if (from == 'null') {
                toast.error("Please Select On Wallet Type")
            } else {

                if (amount < 50) {
                    return toast.error("The minimum transfer amount is 50 USDT.")
                }

                if (from == "Stake_Referral_Wallet") {
                    if (amount > Number(stakedetail?.newstakereff)) {
                        return toast.error("Insufficent balance in Stake reward!")
                    }


                    const data = {
                        from_wallet: "Stake_Referral_Wallet",
                        to_wallet: "Stake_Referral_Wallet",
                        actual_amount: amount,
                        fees: "1",
                        Amount: "1",
                        converted_usdt: "0.1"
                    }

                    const datas = await stakingInternalTransfer(data)
                    //console.log(datas)
                    if (datas.status == 'true') {
                        toast.success(datas.msg)
                        setInterval(() => {
                            window.location.reload()
                        }, 2000)
                    } else {
                        toast.error(datas.msg)
                    }

                } else {
                    if (amount > Number(stakedetail?.newstakewithdraw)) {
                        return toast.error("Insufficent balance in Stake withdrawal!")
                    }

                    const data = {
                        from_wallet: "Stake_Withdraw_Wallet",
                        to_wallet: "Stake_Withdraw_Wallet",
                        actual_amount: amount,
                        fees: "1",
                        Amount: "1",
                        converted_usdt: "0.1"
                    }
                    const datas = await stakingInternalTransfer(data)
                    //console.log(datas)
                    if (datas.status == 'true') {
                        toast.success(datas.msg)
                        setInterval(() => {
                            window.location.reload()
                        }, 2000)
                    } else {
                        toast.error(datas.msg)
                    }
                }

            }

        } else {
            toast.error("Please Transfer Greater Than 0 USDT")
        }

    }
    const handleAllmessage = (message) => {
        toast(`${message}`)
    }



    const getStakeDetails = async () => {
        try {

            const stakeDetails = await stakeDetail()
            console.log(stakeDetails)
            setStakeDetail(stakeDetails)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        getStakeDetails()
    }, [])


    return (
        <>
        {loading ? (
          <>
            <div className="loading-spinner"></div>
          </>
        ) : (
          <>
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">
                <ToastContainer />
                <div class="col-lg-6 col-12" >
                    <div class="card px-5 py-4 h-100">
                        <div class="content_internal_transfer">
                            <h5 class="text-center mb-0">INTERNAL TRANSFER STAKE</h5>
                            <div class="d-flex justify-content-between align-items-center pt-4">
                                <div class="text-center">
                                    <p class="mb-2">Stake Reward</p>
                                    <h6>{Number(stakedetail?.newstakereff).toFixed(2) || 0} USDT</h6>
                                </div>
                                <div class="text-center">
                                    <p class="mb-2">Stake Withdraw</p>
                                    <h6>{stakedetail?.newstakewithdraw || 0} USDT</h6>
                                </div>
                            </div>
                            <div class="text-center">

                                <div class="d-flex justify-content-between align-items-center pt-4">
                                    <div class="text-center">
                                        <div class="day_count mb-2">
                                            <span>TWA</span>
                                            <i class="fa-solid fa-circle-exclamation text-white"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                data-bs-custom-class="custom-tooltip"
                                                data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)" onClick={() => handleAllmessage(`Total amount available to withdrawal`)}></i>
                                        </div>
                                        <h6>{stakedetail?.TotalWithdrawAmount || 0} USDT</h6>
                                    </div>
                                    <div class="text-center">
                                        <div class="day_count mb-2">
                                            <span>BWA</span>
                                            <i class="fa-solid fa-circle-exclamation text-white"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                data-bs-custom-class="custom-tooltip"
                                                data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)" onClick={() => handleAllmessage(`Balance amount available to withdrawal`)}></i>
                                        </div>
                                        <h6>{stakedetail?.BalanceWithdrawAmount || 0} USDT</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 mt-4 mt-lg-0 mt-md-0">
                    <div class="card px-5 py-4 h-100">
                        <form onSubmit={handleInternalTransfer}>
                            <div class="d-flex justify-content-between align-items-center gap-4">
                                <div class="w-100">
                                    <label for="">Wallet</label>
                                    <select class="form-select mb-4 mt-2" aria-label="Large select example" id='from_wallet' required>
                                        <option value="null" selected>Select Wallet</option>
                                        <option value="Stake_Referral_Wallet">Stake Reward</option>
                                        <option value="Stake_Withdraw_Wallet">Stake Withdrawal</option>
                                    </select>
                                </div>
                                {/* <div class="interchange waves-effect waves-light btn-primary d-flex justify-content-center align-items-center" style={{ borderRadius: "3rem", height: "3rem", width: "7rem" }}>
                                    <i class="fa-solid fa-arrow-right-arrow-left"></i>
                                </div> */}

                            </div>
                            <div>
                                <label for="">Current Amount (USDT)</label>
                                <input type="text" class="form-control mt-2" placeholder="0.00" value={amount} onChange={(e) => setAmount(e.target.value)} />
                            </div>
                            <div class="text-center mt-4">
                                <button type="submit" class="btn btn-primary waves-effect waves-light">Transfer</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="col-lg-12 mt-4">
                    <div className=" card table-responsive text-center">
                        <table className="table table-borderless border-top text-center">
                            <thead className="border-bottom text-center">
                                <tr>
                                    <th>S.No</th>
                                    <th>Amount</th>
                                    <th>Hash </th>
                                    <th></th> 
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((e , i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i+1}</td>
                                                <td>{parseFloat(e.claim_amount_USDT).toFixed(2)}</td>


                                                <td>
                                                    {
                                                        e.Transaction_Hash == 'inetrnaltransfer' ? <>
                                                            <p>Internal Transfer</p>
                                                        </> : <>{e.Transaction_Hash.slice(0, 10)}
                                                        </>
                                                    }
                                                </td>
                                                <td><Link to={`https://bscscan.com/tx/${e.Transaction_Hash}`}>Go</Link></td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                     
                      {
                        count > 10 && <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={count}
                        pageRangeDisplayed={count / 10}
                        onChange={handlePageChange}
                    />
                      }

                        


                    </div>
                </div>
            </div>

        </div>
        </>
      )}
    </>
    )
}
