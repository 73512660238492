import React, { useEffect, useState } from "react";
import lodinggif from "../admin_assets/assets/gif/loadwalk.gif";
import {
  PlanBotApiHistory,
  PlanTradeHistoryApi,
  referral_details,
} from "../common/Api";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "react-js-pagination";

export default function TradeHistory() {
  const [history, setHistory] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(true);

  const getTradHistory = async () => {
    try {
      const submitdata = {
        pageno: page,
      };

      console.log("pageno", page);

      const data = await PlanBotApiHistory(submitdata);
      console.log(data);
      setHistory(data.data);
      setCount(data.count);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTradHistory();
  }, [page]);

  const handlePageChange = async (pageNumber) => {
    setActivePage(pageNumber);
    console.log(`active page is ${pageNumber}`);
    const submitdata = {
      pageno: pageNumber,
    };
    const data = await PlanBotApiHistory(submitdata);
    console.log(data);
    setHistory(data.data);
    setCount(data.count);
  };

  console.log(history);
  console.log(count);

  return (
    <>
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />

              <div className="col-xl-12 mb-4 col-lg-12 col-12">
                <div class="card">
                  <h5 class="card-header text-white">Bot History</h5>
                  <div class="table-responsive text-nowrap ClaimRewardTable p-0 p-lg-3">
                    <table class="table">
                      <thead>
                        <tr class="text-nowrap">
                          <th className="text-white">S.No.</th>
                          <th className="text-white">Trade Amount</th>
                          <th className="text-white">Date </th>
                          <th className="text-white">Wallet Type</th>
                        </tr>
                      </thead>
                      <tbody class="table-border-bottom-0">
                        {history.length > 0 ? (
                          <>
                            {history?.map((items, i) => {
                              return (
                                <tr key={i}>
                                  <td className="para-color">{i + 1}</td>
                                  <td className="para-color">
                                    <span>{items.Plan_name}</span>
                                    <p className="mb-0">
                                      Amount : {items.Plan_Amount} USDT
                                    </p>
                                  </td>
                                  <td className="para-color">
                                    {new Date(items.start_date).toLocaleString()}
                                  </td>
                                  <td className="para-color">
                                    {items.Wallet_Type}
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <p>No Record Found!</p>
                        )}
                      </tbody>
                    </table>
                    {count > 10 && (
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={count}
                        pageRangeDisplayed={count / 10}
                        onChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
