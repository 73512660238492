import React from "react";
import DashboardBodyComponents from "./DashboardBodyComponents";
import { ContextProvider } from "../contextApi/DataContext";

export default function DashboardComponents() { 

  return (
    
      <DashboardBodyComponents />

)
}
