import React, { useEffect, useState, useCallback } from "react";
import logo from "../../admin_assets/assets/img/branding/new_applogo.webp";
import { Link, useNavigate } from "react-router-dom";
import { user_details_two, userWalletBalance } from "../../common/Api";
import { toast, ToastContainer } from "react-toastify";

export default function Header({ isSidebarOpen, setIsSidebarOpen }) {
  const [balance, setBalance] = useState(0);
  const [mpBalance, setMpBalance] = useState(0);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchUserBalances = async () => {
      try {
        const userBalances = await userWalletBalance();
        
        const healthBalance = (Number(userBalances.Health) + Number(userBalances.Refferral)).toFixed(2);
        const mpHealthBalance = (Number(userBalances.MPHealth) + Number(userBalances.MPRefferral)).toFixed(2);
  
        setBalance(healthBalance);
        setMpBalance(mpHealthBalance);
      } catch (error) {
        console.error('Error fetching user balances:', error);
      }
    };
  
    fetchUserBalances();
  }, []);
   

  const handleLogout = useCallback(() => {
    localStorage.clear();
    localStorage.setItem("phn", 1);
    navigate("/");
  }, [navigate]);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen((prev) => !prev);
  }, [setIsSidebarOpen]);

  const handleRefresh = useCallback(async () => {
    try {
      const data = await user_details_two("Home_page");
      if (data) {
        toast.success("Refresh done");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to refresh", error);
    }
  }, []);

  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0">
        <button
          className="nav-item nav-link px-0 me-xl-4"
          onClick={toggleSidebar}
        >
          <i className="ti ti-menu-2 ti-sm"></i>
        </button>
      </div>
      <ToastContainer />
      <div
        className="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        <ul className="navbar-nav flex-row align-items-center ms-auto navResponsiveReferal">
          <li style={{ cursor: "pointer" }} onClick={handleRefresh}>
            <button className="balance btn btn-primary waves-effect waves-light">
              Refresh
            </button>
          </li>
          <li>
            <Link to="/user/balance">
              <button className="balance btn btn-primary waves-effect waves-light">
                Balance {balance}
              </button>
            </Link>
          </li>
          <li>
            <Link to="/user/balance">
              <button className="balance btn btn-primary waves-effect waves-light">
                MP Balance {mpBalance}
              </button>
            </Link>
          </li>
          <li className="nav-item navbar-dropdown dropdown-user dropdown">
            <Link
              className="nav-link dropdown-toggle hide-arrow"
              to=""
              data-bs-toggle="dropdown"
            >
              <div className="avatar avatar-online">
                <img src={logo} alt="User Avatar" className="h-auto rounded-circle" />
              </div>
            </Link>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <Link
                  className="dropdown-item"
                  to="pages-account-settings-account.html"
                >
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar avatar-online">
                        <img src={logo} alt="User Avatar" className="h-auto rounded-circle" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <span className="fw-medium d-block">
                        {user && user.email}
                      </span>
                      <small className="text-muted">User</small>
                    </div>
                  </div>
                </Link>
              </li>
              <li className="dropdown-divider"></li>
              <li>
                <Link className="dropdown-item" to="/user/profile">
                  <i className="ti ti-user-check me-2 ti-sm"></i>
                  <span className="align-middle">My Profile</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="pages-account-settings-account.html"
                >
                  <i className="ti ti-settings me-2 ti-sm"></i>
                  <span className="align-middle">Settings</span>
                </Link>
              </li>
              <li className="dropdown-divider"></li>
              <li>
                <Link
                  className="dropdown-item"
                  onClick={handleLogout}
                  target="_blank"
                >
                  <i className="ti ti-logout me-2 ti-sm"></i>
                  <span className="align-middle">Log Out</span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="navbar-search-wrapper search-input-wrapper d-none">
        <input
          type="text"
          className="form-control search-input container-xxl border-0"
          placeholder="Search..."
          aria-label="Search..."
        />
        <i className="ti ti-x ti-sm search-toggler cursor-pointer"></i>
      </div>
    </nav>
  );
}
