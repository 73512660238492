import { createContext, useContext, useState ,useEffect} from "react";
import { ToastContainer, toast } from "react-toastify";
import formatDate from "../common/DateFormatter";
import {
  auto_update_missing_rewards,
  autoRoiReward,
  home_page_content,
  maximum_target,
  JWperformanceBonus,
  missing_reward_update_two,
  MphealthReward,
  mpPlanDetail,
  process_rewards,
  roll_process_rewards,
  step_count_status_update,
  update_plan_end_date,
  user_details_two,
  user_target_set,
  mPlanAutoExpire,
} from "../common/Api";




const HomeContext=createContext()

 export const ContextProvider=({children})=>{
    

  
    //   const [data, setData] = useState({});
        const [token, setToken] = useState((JSON.parse(localStorage.getItem('user')))?.token||'' )
        // const [user,setUser]=useState(JSON.parse(localStorage.getItem('user')))
        const [info, setInfo] = useState({});
        const [PageDetail, setPageDetail] = useState("Home_page");
        // console.log(token)
        
    
        const [loading, setLoading] = useState(true);
      
    
        const [convertedDate, setConvertedDate] = useState("");
       
        const [show, setShow] = useState(false);
      
        const [mpdetail, setMpdetail] = useState({});
        const [isInfoLoaded, setIsInfoLoaded] = useState(false);
         
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log( "user details is ", user)

     const get_user_detail_two = async () => {
        if(isInfoLoaded) return ;
        
        try {
          const data = await user_details_two(PageDetail);
          console.log(data)
          setInfo(data);
          setIsInfoLoaded(true)
          const date = data.plan_end_date;
          let dateMDY = `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`;
          setConvertedDate(dateMDY);
         

        } catch (error) {
          toast.error(error);
        }
      };

        const get_plan_update_end_date = async () => {
          try {
            await update_plan_end_date(info.user_id);
          } catch (error) {
            toast.error(error);
          }
        };

        const get_roll_process_reward = async () => {
            try {
              await roll_process_rewards(info.user_id);
            } catch (error) {
              toast.error(error);
            }
          };

         const process_reward = async () => {
            try {
              await process_rewards(info.user_id);
            } catch (error) {
              toast.error(error);
            }
          };
         const mPlanExpire = async () => {
            try {
              await mPlanAutoExpire(info.user_id);
            } catch (error) {
              toast.error(error);
            }
          };
      
  const Missing_reward_update = async () => { 
    try {
      const date = new Date();
      const newdatec = formatDate(date);

      const data = await missing_reward_update_two(newdatec, info.Plan_Step);
      await auto_update_missing_rewards();
    } catch (error) {
      console.log(error);
    }
  };

  const mpPlan_detail = async () => { 
    try {
      const data = await mpPlanDetail();
      //console.log(data)
      setMpdetail(data);
    } catch (error) {
      //console.log(data);
    }
    if(loading){

      setLoading(false);
    }
  };

  const jw_performance_bonus = async () => {
    try {
      await JWperformanceBonus();
    } catch (error) {
      toast.error(error);
    }
    if(loading){

      setLoading(false);
    }
  };

  const mp_health_reward = async () => {
    try {
      const date = new Date();
      const newdatec = formatDate(date);

      const data = await MphealthReward(newdatec, info.Plan_Step);
    } catch (error) {
      console.log(error);
    }
    
  };
  const handleTargetMaxCount = async () => {
    try {
      const target = document.querySelector("#target").value;
    
      toast.success("Target updated successfully");
      setShow(false);  
      const data = await user_target_set(target);
      await get_user_detail_two();  
    } catch (error) {
      toast.error("Failed to update target");
    }
  };

   useEffect(()=>{
        if(user){
          
          get_user_detail_two()
          if(loading){

            setLoading(false);
          }
        }

      },[user])


    //   useEffect(() => {
    //     if (!user) {
    //       localStorage.clear();
    //       navigate("/login");
    //     }
    
    //     // Initial setup after the page is rendered
    //     setDate(new Date().toLocaleDateString());
    
    //     get_user_detail_two();
    //   }, [user]); 

      useEffect(() => {
        if (!info?.user_id) return;
        
        const fetchData = async () => {
          try {
            const [
              mpDetailData,
              planUpdate,
              rollReward,
              processRewardData,
              healthRewardData,
              performanceBonus,
              autoRoi,
              mPlanExpireData
            ] = await Promise.all([
              mpPlan_detail(),
              get_plan_update_end_date(),
              get_roll_process_reward(),
              process_reward(),
              mp_health_reward(),
              jw_performance_bonus(),
              autoRoiReward(),
              mPlanExpire()
            ]);
            
            if (info.Plan_Step) {
              Missing_reward_update();
            }
          } catch (error) {
            toast.error("Failed to fetch data");
          }
        };
        fetchData();
      }, [info?.user_id]);

     


    return(
        <HomeContext.Provider value={{loading,info,handleTargetMaxCount,token,convertedDate,mpdetail,get_user_detail_two}}>
            {children}
        </HomeContext.Provider>
    )
}


export  const UseContext=()=>{
    return useContext(HomeContext)
}


