function PaginationComponent({ totalPage, setPageNumber ,currentPage}) {

  
  const handlePrev = () => setPageNumber((prev) => Math.max(prev - 1, 0));
  const handleNext = () => setPageNumber((prev) => Math.min(prev + 1, totalPage-1));
 

  return (
    <div>
      <span>
      <div className="d-flex justify-content-center mt-3 ">
          <button
            className="btn btn-secondary me-2"
            onClick={handlePrev}
            disabled={currentPage < 0}
          >
            Previous
          </button>
          <span className="text-white mx-2 mt-2">
            Page {currentPage} of {totalPage}
          </span>
          <button
            className="btn btn-secondary ms-2"
            onClick={handleNext}
            disabled={currentPage === totalPage}
          >          
            Next
          </button>
        </div>
      </span>
    </div>
  );
}
 export default PaginationComponent;