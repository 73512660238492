import React, { useEffect, useState } from "react";
import {
  premium_transfer_history,
  stake_withdrawal_history,
} from "../common/Api";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import DataTable from "react-data-table-component";
import styled from "styled-components";

export default function StakingWithdrawalHistory() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filterWallet, setFilterWallet] = useState("");

  const get_withdrawal_history = async () => {
    const data = await stake_withdrawal_history(page);
    setData(data);
    setCount(data.count);
    setLoading(false);
  };

  useEffect(() => {
    get_withdrawal_history(1);
  }, []);

  const columns = [
    {
      name: "Sr no.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => parseFloat(row.claim_amount_USDT).toFixed(2) + " USDT",
      sortable: true,
    },
    {
      name: "Hash",
      selector: (row) => row.Transaction_Hash.slice(0, 20) + " .......",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Link to={`https://bscscan.com/tx/${row.Transaction_Hash}`}>Go</Link>
      ),
    },
  ];

  const filteredData = data.filter(
    (item) => !filterWallet || item.Wallet_type.includes(filterWallet)
  );

  return (
    <>
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="">
              <div className="card mb-4 p-4">
                <div className="table-responsive text-center">
                  <select
                    value={filterWallet}
                    onChange={(e) => setFilterWallet(e.target.value)}
                    className="mb-3"
                  >
                    <option value="">Select Wallet</option>
                    <option value="New_Stake_Withdraw_Wallet">
                      New_Stake_Withdraw_Wallet
                    </option>
                    <option value="New_Stake_Referral_Wallet">
                      New_Stake_Referral_Wallet
                    </option>
                  </select>
                  <DataTable
                    className="table table-borderless border-top text-center"
                    columns={columns}
                    data={filteredData}
                    pagination
                    highlightOnHover
                    responsive
                  />
                  {/* <table className="table table-borderless border-top text-center">
                <thead className="border-bottom text-center">
                  <tr>
                    <th>Sr no.</th>
                    <th>Amount</th>
                    <th>Hash</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((e, index) => {
                    return (
                      <tr key={e.id}>
                        <td>{index + 1}</td>

                        <td>{parseFloat(e.Withdraw_JW).toFixed(2)} JW</td>

                        <td>{e.Transaction_Hash.slice(0, 20)} .......</td>
                        <td>
                          <Link to={`https://bscscan.com/tx/${e.Hash}`}>
                            Go
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
